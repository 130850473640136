import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    APIURL: string = environment.apiURL + 'api/';
    constructor(private http: HttpClient, private router: Router) { }

    getAllCourses() {
        return this.http.get<any>(this.APIURL + "my-courses")
    }

    getCourseDetails(slug: string) {
        return this.http.get<any>(this.APIURL + "get-course-details/"+ slug)
    }

    getAssesmentDetails(assesmentId: any) {
        return this.http.get<any>(this.APIURL + "get-assesment-details/"+ assesmentId)
    }

    startAssessment(courseId:any,lessonId:any,assesmentId: any) {
        return this.http.post<any>(this.APIURL + "assesment-start",{course_id:courseId,lesson_id:lessonId,assesment_id:assesmentId});
    }

    submitSingleAssesment(assessmentDetail){
        return this.http.post<any>(this.APIURL + "assesment-single-submit",assessmentDetail);
    }

    submitFinalAssesment(assesmentTestId){
        return this.http.post<any>(this.APIURL + "assesment-final-submit",{assesment_test_id:assesmentTestId});
    }

    markLessonAsComplete(courseId,lessonId){
        return this.http.get<any>(this.APIURL + "mark-lesson-complete/"+ courseId+"/"+lessonId);
    }

    getLessionDetails(body:any){
        return this.http.post(this.APIURL+"get-lesson-details",body)
    }
    getResult(assesment_test_id)
    {
        return this.http.get<any>(this.APIURL + "get-assessment-result/"+assesment_test_id);
    }
    getResultPreview(assesment_test_id)
    {
        return this.http.get<any>(this.APIURL + "get-assessment-result-preview/"+assesment_test_id);
    }

}