import { Injectable, NgZone } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "app/services/auth.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private authService:AuthService,private ngZone:NgZone) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if(localStorage.getItem('qa-academy-auth-storage'))
        {
            return true;
        }

        if (!localStorage.getItem('qa-academy-auth-storage')){
            this.router.navigate(['/login']);
        }
        return false;
    }
}



