import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { AuthService } from 'app/services/auth.service'
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import country_list from 'app/constants/country_list.json';
import { EncryptionService } from 'app/services/encryption.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild("phoneOrEmailInput", { static: true }) phoneOrEmailInput: ElementRef<HTMLInputElement>;

  isOTPSent = false;
  enableResend = false;
  timeInSeconds: number = 150; // 2:30 mins
  resentTime: string;
  country: any[] = country_list;

  OTPinput: string ='';
  phoneOrEmail: string = "";
  countryInput: string = "+91";
  trueOTP: string;
  isPhoneNumber: boolean = false;

  isLoading: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private toastr: ToastrService, private authService: AuthService, private router: Router,private activatedRoute:ActivatedRoute,private encryptionService: EncryptionService) {
    this.activatedRoute.queryParams
    .subscribe((params) => 
      {
        if(params.email !=null && params.password_code !=null){
          this.router.navigateByUrl('lms-admin/reset-password?password_code=' + params.password_code + '&email=' + params.email)
        }
        
  });
  }

  ngOnInit(): void {
    if(localStorage.getItem('qa-academy-auth-storage')) {
      this.router.navigateByUrl('/dashboard'); 
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  sendOTP() {
    if(this.phoneOrEmail && this.phoneOrEmail.length > 5) {
      if(!this.isPhoneNumber && !this.isEmailValid(this.phoneOrEmail)) {
        this.toastr.error('Please enter valid email address')
        return;
      }
      let username = this.phoneOrEmail.toLowerCase();
      if(this.isPhoneNumber) {
        username = this.countryInput+" "+this.phoneOrEmail
      }
      this.isLoading = true;
      this.authService.sendOTP(username).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        if(res['success'] != undefined && res['success'] == true){
          this.trueOTP = res.otp
          this.isOTPSent = true;
          this.isLoading = false;
          this.toastr.success(res.message)
          this.enableResend = false;
          this.countdown?.begin();
        }
      }, error => {
        this.isLoading = false;
        this.toastr.error('Email or Phone is not registered with us. Please contact support', 'Failed');
      })
    } else {
      this.toastr.error('Please Enter Email or Phone number');
    }
  }
  
  submitOTP() {
    if(this.OTPinput.length > 0){
    if(this.OTPinput.length === 6) {      
      if(this.trueOTP === this.OTPinput || true) {
        let username = this.phoneOrEmail.toLowerCase();
        if(this.isPhoneNumber) {
          username = this.countryInput+" "+this.phoneOrEmail
        }
        this.isLoading = true;
        this.authService.verifyOtp(username, this.OTPinput).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
         if(res['success'] != undefined && res['success'] == true){
          this.isLoading = false;
          localStorage.setItem("qa-userType","Agent")
          this.toastr.success('Successfully logged in')
          localStorage.setItem('qa-academy-auth-storage', this.encryptionService.encryptUsingAES256(JSON.stringify(res.token_details)));
          this.router.navigateByUrl('/dashboard'); 
        }
          
        }, error => {
          this.isLoading = false;
          this.toastr.error('Incorrect OTP', 'Failed')
        })
      }
    } else {
      this.toastr.error('Please enter 6 digit OTP')
    }
  }else{
    this.toastr.error('Please enter OTP.')
  }
  }

  onOtpChange(event: any) {
    this.OTPinput = event
  }

  resendOTP() {
    this.enableResend = false;
    this.sendOTP();
  }

  timer(event: any) {
    if(event.action === 'done') {
      this.enableResend = true;
    }
  }

  enterPhoneOrEmail(event) {
    const userInput = event.target.value
    this.phoneOrEmail = userInput
    this.isPhoneNumber = this.isNumeric(userInput)
  }

  isNumeric = str => parseFloat(str) === parseFloat(str)

  edit() {
    this.isOTPSent = false
    setTimeout(() => {
      this.phoneOrEmailInput.nativeElement.focus();
      this.phoneOrEmailInput.nativeElement.value = this.phoneOrEmail;
    }, 100)
  }
  isEmailValid = (val:string):boolean => /^[a-zA-Z.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(val);
  // isEmailValid = (val:string):boolean => /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(val);
}
