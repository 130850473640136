<div style="height: 100%">
    <div class="side-bar" style="padding-right: 0;">
        <div class="msm-logo">
            <a routerLink="/"><img src="./assets/logo/msmNewLogo.png"></a>
        </div>
        <div class="menu">
            <div routerLinkActive="active">
                <a routerLink="/dashboard" routerLinkActive="active">
                    <h5><b>Dashboard</b></h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/courses" routerLinkActive="active">
                    <h5><b>My Courses</b></h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/certificate" routerLinkActive="active">
                    <h5><b>My Certificates</b></h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/myProfile" routerLinkActive="active">
                    <h5><b>My Profile</b></h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/logout" routerLinkActive="active">
                    <h5><b>Logout</b></h5>
                </a>
            </div>
        </div>
    </div>
</div>