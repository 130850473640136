<div class="navbar">
    <div class="container user-img">
        <!-- <span class="material-icons md-36 mx-2" style="cursor: pointer;" (click)="menuToggle()">menu</span> -->
        <div class="userlogo">
            <a routerLink="/myProfile" routerLinkActive="active"><img src="./assets/images/userlogo.png"></a>
            <div class="dropdown ">
                <div class="dropdown-toggle" data-toggle="dropdown"> {{this.data.user_name}}</div>
                <div class="dropdown-content dropdown-header ">
                    <a routerLink="/dashboard" routerLinkActive="active">My Dashboard</a>
                    <hr>
                    <a (click)="logout()">Logout</a>
                </div>
            </div>
        </div>
    </div>
</div>
