
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MyCertificateService } from 'app/services/my-certificate.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { FileSaverService } from 'ngx-filesaver';
import { EncryptionService } from 'app/services/encryption.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})

export class CertificateComponent implements OnInit {
  certificateDetails: any[] = [];
  isLoading: boolean = true;
  isDownloading: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  constructor(private certificate: MyCertificateService, private httpClient: HttpClient, private fileSaverService: FileSaverService,
    private encryptionservice:EncryptionService) {
  }

  ngOnInit(): void {
   this.getAllCertificates();
  }

  getAllCertificates() {
    this.isLoading = true;
    this.certificate.myCertificate().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.certificateDetails = res.data;
      this.isLoading = false;
      window.scroll(0,0);
    }, error => {
      this.isLoading = false;
    });
  }

  view(item) {
    window.open(this.encryptionservice.get(item.certificate_url),'_blank');
  }

  downloadFile(item) {
    this.isDownloading = true;
    this.httpClient.get(this.encryptionservice.get(item.certificate_url), {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this.isDownloading = false;
      this.fileSaverService.save(res.body, "Certificate_" + item.certificate_id + ".pdf");
    }, error => {
      this.isDownloading = false;
    });
    return;
  }

}
