<app-spinner *ngIf="isLoading"></app-spinner>

<div *ngIf="!isErrorStatus && !isLoading" >
<div class="container-fluid" *ngIf="!isLoading">
    <div class="page-title-content py-3" >
        <ul>
          <li><a class="choice_title" id ="dashboard" (mouseover)="addHiglightClass('dashboard')" (mouseout)="removeHiglightClass('dashboard')" routerLink="/dashboard">Dashboard</a></li>
          <li><a class="choice_title" id ="mycourses" (mouseover)="addHiglightClass('mycourses')" (mouseout)="removeHiglightClass('mycourses')" routerLink="/courses">My Courses</a></li>
          <li> <a class="choice_title" id ="course" (mouseover)="addHiglightClass('course')" (mouseout)="removeHiglightClass('course')" routerLink="/course/{{courseDetails.course_slug}}">{{courseDetails.course_title}}
              Course</a></li>
          <li> <a class="choice_title" id ="assessment" (mouseover)="addHiglightClass('assessment')" (mouseout)="removeHiglightClass('assessment')">{{assesmentDetails?.assesment_title}}</a></li>
        </ul>
      </div>
      </div>
      <br>
      

      <div class="modal-body justify-content-center">
        <div>
         
          <div class="scroll-backgorund " 
          (scroll)="onscroll()"
          [ngClass]="scrollfixed? 'scroll_fixed':'scroll_nofixed'" 
          *ngIf="resultdetails['is_pass']==true"
          >
          <div class="d-flex " style="justify-content: space-between;" (scroll)="onscroll()"
          [ngClass]="scrollfixed? '  scroll-color-bg':' scroll-color-bg-notfixed'">
            <div>
              <button class="previous btn-pre" *ngIf="showPrevButton" (click)=" previousLesson()">
                <span class="previous"><i class="fa fa-caret-left"></i>  Previous Lesson</span>
              </button>
            </div>
            <div>
              <button (click)="nextLesson()" *ngIf="showNextButton" class="next btn-pre"><span class="previous">Next Lesson  <i class="  fa fa-caret-right"></i></span>
                </button>
            </div>
            </div>
          </div><br>
          <div class="body-font" style="color:  #105F96; margin-bottom: 5px;">Lesson {{lesson_data?.lesson_details?.current_lesson}} of
            {{lesson_data?.lesson_details?.total_lessons}}</div>
            <div class="d-flex" style="justify-content: space-between;">
          <div>
            <h2 class="font-weight-bold font-subheading">{{assesmentDetails?.assesment_title}}</h2></div>
                <div>
                <button class="btn btn-danger lms-button text-bold"
                routerLink="/course/{{courseDetails.course_slug}}">Back to Course</button>
                </div>
            </div>
           
         
  
          <!-- <div class="p-2 bd-highlight">
                  <h6>1:00:00 <span>min</span></h6>
                  <p>Last of the Section</p>
              </div> -->
        </div>
  
        <div class=" mb-3">

          <h6 style="margin-top:5px;" class="font-weight-bold body-font">Quiz Result</h6>
          <hr />
          <div class="row text-center">
            <div class="col-md-4 left-align body-font">
              <span class="question-font font-weight-bold body-font">Correct Answers:</span>
              {{(resultdetails['totalCorrect'])}}/{{resultdetails['totalQus']}}
            </div>
            <div class="col-md-4 body-font">
              <span class="question-font font-weight-bold">Time:</span>
               {{resultdetails?.total_time_taken_in_min | minuteSeconds }}
               <!-- {{assesmentResult['total_time_taken_in_min']}}  -->
            </div>
            <div class="col-md-4 body-font">
              <span class="question-font font-weight-bold">Score:</span> {{resultdetails?.mark}}%
            </div>
          </div>
          <hr />
  
        </div>
      </div>

      <div class="py-4 text-center ">
        <button type="button" class="btn btn-danger bt-md questions" (click)="quizResultDisplay()">View Questions </button>

          <button type="button" class="btn btn-danger bt-md questions"  *ngIf="resultdetails.is_pass==false"  (click)="retakeAssesment()">Retake
            Assessment</button>
      </div>

      <!-- For QuizPreview -->

      <section class="space-margin" *ngIf="quizPreview">
        <h5 class="font-weight-bold text-center py-2">Question's Answered</h5>
           <app-quiz-result [quizList]="resultDetailsPreview"></app-quiz-result>
      </section><br>
    </div>

      <div class="container" *ngIf="isErrorStatus && !isLoading">
        <div class="error-area ptb-100">
          <div class="d-table">
              <div class="d-table-cell">
                  <div class="container">
                      <div class="error-content">
                          <h3>Warning</h3>
                          <p>{{iserrorStatus}}</p>
                          <div class="btn-box">
                            <button class="btn danger-btn home-btn" routerLink="/course/{{courseDetails.course_slug}}"><p class=" home-btn1">Back to Course</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
        </div>


      
    
   

