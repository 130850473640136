<div class="navbar">
    
    <div class="container user-img">
        <button (click)="sideNav()"  type="button" class="navbar-toggler new">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- <span class="material-icons md-36 mx-2" style="cursor: pointer;" (click)="menuToggle()">menu</span> -->
        <div class="userlogo">
            <a routerLink="/myProfile" routerLinkActive="active"><img [src]="dataLoaded == true ? assetBaseUrl+userDetails?.photo : './assets/images/userlogo.png'"></a>
            <div class="dropdown ">
                <div class="dropdown-toggle" data-toggle="dropdown">{{userDetails?.name}}</div>
                <div class="dropdown-content dropdown-header ">
                    <a routerLink="/dashboard" routerLinkActive="active">My Dashboard</a>
                    <hr>
                    <a routerLink="/logout" routerLinkActive="active">Logout</a>
                   
                </div>
            </div>
        </div>
       
    </div>
</div>

