<div class="login">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="login-logo h-100 px-3">
          <div class="row align-items-center h-100" style="min-height:calc(100vh - 118.43px)">
            <div class="col-12 col-sm-6 mx-auto" style="text-align:left;" >
              <img class="logo"style="width:100%;" src="./assets/images/login1.png" alt="login" loading="lazy">
          </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div  class="login-form login-signin">
          <div  class="row">
          <div class="mt-4">
            <a routerLink="/"><img src="./assets/images/bannerLogo.png" class="img"  alt="logo"
                loading="lazy"></a>
          </div>
          </div>
          <div  class="text-center mb-11">
            <h3  class="title font-weight-bolder font-size-h4 font-size-h1-lg"> Welcome to MSM </h3>
            <span class="form-header font-size-h4"> 
              Sign In
            </span>
          </div>
          <form #form="ngForm">
            <div class="col-md-15 mb-3">
              <mat-form-field appearance="outline">
                <mat-label> Email </mat-label>
                <input matInput placeholder="Enter Email"
                    [required]="true" name="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                    [(ngModel)]="email">
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                      Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required')">
                      Email is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-15 mt-3">
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="Enter Password" [formControl]="passwordFormControl" [errorStateMatcher]="matcher"
                    name="password"  [required]="true" [(ngModel)]="password">
                    <mat-error *ngIf="passwordFormControl.hasError('password') && !passwordFormControl.hasError('required')">
                      Please enter a valid password
                    </mat-error>
                    <mat-error *ngIf="passwordFormControl.hasError('required')">
                      Password is required</mat-error>
            </mat-form-field>
        </div>
        <div class="forgotpassword">
          <label>
            <input type="checkbox" name="remember" value="remember" id="remember"
               class="loginbox" (change)="checkValue()" [(ngModel)]="remember">
            <span class="formlabel">Remember Me</span>
          </label>
          <label>
            <a  routerLink="/lms-admin/forgot-password" class="lbl2 text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"> Forgot Password ? </a>
          </label>
        </div>
      
      <div  class="pb-lg-0 pb-5 d-grid">
        <button  class="btn" type="submit" mat-raised-button (click)="submit()" [disabled]="isLoading"><span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span> Sign In </button>
      </div>
     </form>
</div>
</div>
      </div>
    </div>
  </div>
    <!-- <div class="col-6">
    <div class="login-logo h-100 px-3">
        
          <div class="row align-items-center h-100" style="min-height:calc(100vh - 118.43px)">
            <div class="col-12 col-sm-6 mx-auto" style="text-align:left;" >
              <img class="logo"style="width:100%;" src="./assets/images/login1.png" alt="login" loading="lazy">
          </div>
          </div>
        </div>
      </div> -->
      
  