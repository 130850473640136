import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.scss']
})
export class QuizResultComponent implements OnInit {

  @Input() quizList:any;
  resultDetailsPreview:any = [];

  constructor() { }

  ngOnInit(): void {
    this.resultDetailsPreview = this.quizList;
    // console.log("result..",this.resultDetailsPreview);
  }

}
