import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from "@angular/common/http";
import { AuthService } from "app/services/auth.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { environment } from "environments/environment";
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { EncryptionService } from "app/services/encryption.service";

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private isRefreshing = false;
    constructor(
        private inj: Injector, authService: AuthService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401) {
                // console.log('error', err?.url?.split("/")[4])
                // let auth = this.inj.get(AuthService);
               
                // auth.refreshToken();
                // if(err?.url?.split("/")[4] === "refresh-token"){
                //     localStorage.removeItem('qa-academy-auth-storage');
                //     this.router.navigateByUrl('/login'); 
                // }
                // return EMPTY;
                return this.handle401Error(request, next);
            }
            if (request.url.toLocaleLowerCase().startsWith(environment.apiURL) && !request.url.toLocaleLowerCase().includes('refresh-token')) {
                if (request.responseType == 'blob' && err.error != null && err.status!=0) {
                    this.handleBlobError(err);
                }
                else {
                    const error = err.error != null && err.error.Message ? this.errorMsg(err.error.Message) : err.statusText;
                    if(error.toLocaleLowerCase() == 'invalid token.'){
                       // this.toasterService.pop("error", error);
                        let auth = this.inj.get(AuthService);
                        auth.logout(true);
                        return EMPTY;
                    }
                    else if (error.toLocaleLowerCase() !== 'ok') {
                       // this.toasterService.pop("error", error);
                    }
                }
            }
            return throwError(err);
        }));
    }

    errorMsg(error) {
        if (error.charAt(error.length - 1) == ".") {
            return error;
        } else {
            return error + ".";
        }
    }

        private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                let encryptionService = this.inj.get(EncryptionService);
            let sourceToken = localStorage.getItem('qa-academy-auth-storage') ? JSON.parse(encryptionService.decryptUsingAES256(localStorage.getItem('qa-academy-auth-storage'))).refresh_token:null;
            if (sourceToken != null){
                let auth = this.inj.get(AuthService);
                return auth.refreshSourceToken(sourceToken).pipe(
                    switchMap((token: any) => {
                      this.isRefreshing = false;
                      this.refreshTokenSubject.next(token.token_details.access_token);
                      localStorage.setItem('qa-academy-auth-storage', encryptionService.encryptUsingAES256(JSON.stringify(token.token_details)));
                      return next.handle(this.addTokenHeader(request, token.token_details.access_token));
                    }),
                    catchError((err) => {
                      this.isRefreshing = false;
                      localStorage.removeItem('qa-academy-auth-storage');
                      this.router.navigateByUrl('/login'); 
                      return throwError(err);
                    })
                  );
            }

            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap((token) => next.handle(this.addTokenHeader(request, token)))
              );
            }
        }

        private addTokenHeader(request: HttpRequest<any>, token: string) {
            return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token)});
         }

        async handleBlobError(err) {
        const error1 = JSON.parse( await err.error.text());
        const error = error1 != null && error1.Message ? this.errorMsg(error1.Message) : err.statusText;
        if(error.toLocaleLowerCase() == 'invalid token.'){
          //  this.toasterService.pop("error", error);
            let auth = this.inj.get(AuthService);
            auth.logout(true);
            return EMPTY;
        }
        else {
           // this.toasterService.pop("error", error);
        }
    }

}