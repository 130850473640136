<div class="">
    <div class="myCourse">
        <h2 class="heaading-fontsize"> <b>My Courses</b></h2>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="row" *ngIf="!isLoading">
        <div *ngFor="let item of courses" class="col-md-6 col-xs-12 col-sm-12 col-lg-4 .col-xl-6  ">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a [routerLink]="'/course/'+item.course_slug" class="d-block image">
                        <img [src]="item.course_banner | urlDecryption: image_base_url" alt="image">
                    </a>
                </div>

                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                    </div>

                    <h5 class="font-subheading"><b><a [routerLink]="'/course/'+item.course_slug">{{item.course_title}}</a></b></h5>
                    <p class="parghrap body-font">{{item.course_about}}</p>
                    <br>
                    <div class="progress">
                        <div class="progress-bar bg-danger" role="progressbar"
                            [style]="'width:'+item.course_completed_percent+'%;'" aria-valuenow="10" aria-valuemin="0"
                            aria-valuemax="100">{{item.course_completed_percent}}%</div>
                    </div>

                    <ul class="courses-box-footer d-flex justify-content-between align-items-center"
                        style="list-style-type:none">
                        <li style="font-size:14px"><i class='flaticon-agenda'></i> {{item.total_lesson}} Lessons</li>
                        <li *ngIf="item.total_agent_completed>1000" style="font-size:14px"><i class='flaticon-user'></i> {{item.total_agent_completed}} Agents
                            Completed</li>
                    </ul>
                </div>
            </div>
            
        </div>

    </div>
    <div class="text-center " style="margin-top: 10px;">
        <h3 class="font-subheading" *ngIf="!isLoading && courses.length == 0">
          No course Found!
        </h3>
      </div>
</div>
<ngx-scrolltop></ngx-scrolltop>