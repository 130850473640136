import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    APIURL: string = environment.apiURL + 'api/';
    constructor(private http: HttpClient, private router: Router) { }

    myDashbaord() {
        return this.http.get<any>(this.APIURL + "my-dashboard")
    }
}