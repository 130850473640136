import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CourseService } from 'app/services/course.services';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { EncryptionService } from 'app/services/encryption.service';
@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('assesmentResultModal') assesmentResultModal;
  @ViewChild('introVideoModalData') introVideoModalData;

  private onDestroy$: Subject<void> = new Subject<void>();
  @ViewChild('cd', { static: false }) countdown!: CountdownComponent;
  public courseDetails: any = {course_title: ""};
  public assetBaseUrl: string;
  public isLoading: boolean = false;
  public courseEnabled: boolean = true;
  public parent_hide:boolean = false;
  public courseErrMsg: string;

  flag=false;
  closeModal: string;
  lessonDetails : any;
  assesmentDetails : any;
  assesmentQuestionIndex = 0;
  selectedChoiceId = -1;
  assesmentTestId = null;
  assesmentResult = null;
  slug : any;
  quizAnswerMap = new Map<string, number>();

  config: CountdownConfig = { leftTime: 0};
  notify = '';
  completedLessonId = null;
  curriculumSelected = false;

  ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title'
  };

  constructor(private courseService: CourseService, private route: ActivatedRoute,private modalService: NgbModal,
    private fileSaverService: FileSaverService,private httpClient: HttpClient,private toasService : ToastrService,
     private encryptionservice:EncryptionService,private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe((param : Params) => {
      this.slug = param['slug'];
      this.getCourseDetails(param['slug']); 
      if(localStorage.getItem('isCurriculumSelected') == '1'){
        this.curriculumSelected = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getCourseDetails(slug) {
    this.isLoading = true;
    this.courseService.getCourseDetails(slug).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.assetBaseUrl = this.encryptionservice.get(res.course_assets_base_url);
      this.courseDetails = res.data;
      // console.log('courses........',this.courseDetails);
      this.isLoading = false;
      this.courseEnabled = true;
      window.scroll(0,0);
    }, error => {
      this.isLoading = false;
      this.courseErrMsg = error.error.message ? error.error.message:'Contact Support Team';
      this.courseEnabled = false;
    });
  }

  refreshPageDetails() {
    this.courseService.getCourseDetails(this.slug).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.assetBaseUrl = this.encryptionservice.get(res.course_assets_base_url);
      this.courseDetails = res.data;
    });
  }

  getDuration(time) {
    if(Number(time) < 60) {
      return Number(time) + " min"
    } else {
      return Number(time)/60 + " hour"
    }
  }

  durationConvert(name) {
    let min = this.courseDetails.course_includes.map(i => i[name]).filter(i => i !== undefined)[0]
    let hours = (min/60);
    return hours.toFixed(1);
    }

  getCourseInclude(name) {
    // console.log(name);
    return this.courseDetails.course_includes.map(i => i[name]).filter(i => i !== undefined)[0]
  }

  playLessonVideo(chapterDetails,lessonDetails,modalData){
      this.lessonDetails = lessonDetails;
      this.lessonDetails['chapter_name'] = chapterDetails['chapter_name'];
      if(this.lessonDetails['is_lock'] != undefined && this.lessonDetails['is_lock'] == true){
        this.toasService.error('Sorry,You cannot access this lesson.')
        return;
      }
      if(lessonDetails.lesson_host == "AUDIO"){
        this.router.navigate(['course/'+this.slug + '/lesson/' +lessonDetails.lesson_slug]);
      }
      else if(lessonDetails.lesson_host == "PDF"){
        window.open(this.assetBaseUrl+this.encryptionservice.get(lessonDetails.lesson_url), "_blank");
      }
      else if(lessonDetails.lesson_host == "VIDEO" || lessonDetails.lesson_host == "VIMEO"){
        if(lessonDetails.lesson_url != undefined && lessonDetails.lesson_url != null){
          // this.triggerModal(modalData);
          this.router.navigate(['course/'+this.slug + '/lesson/' +lessonDetails.lesson_slug]);
        }
      }else{
        if(lessonDetails.lesson_slug != undefined && lessonDetails.lesson_slug != null){
          this.router.navigate(['course/'+this.slug + '/lesson/' +lessonDetails.lesson_slug]);
        }
      }
      
  }

  playChapter(chapter, modalData) {
    this.lessonDetails = {};
      this.lessonDetails['chapter_name'] = chapter['chapter_name'];
      this.lessonDetails.lesson_url = chapter.chapter_url;
      this.lessonDetails.lesson_host = chapter['chapter_host'];
      this.lessonDetails.lesson_thumb_image=chapter.chapter_thumb_image;
    if(chapter.chapter_url != undefined && chapter.chapter_url != null){
      this.triggerModal(modalData);
    }
  }
  playIntroVideo(item, introVideoModalData)
  {
    //this.courseDetails.overview_video_host='Self';
    this.courseDetails.overview_video_link=item.overview_video_link;
    if(item.overview_video_link != undefined &&item.overview_video_link != null){
      this.triggerModal(introVideoModalData);
    }

  }

  downloadFile(fileName, fileUrl) {
        //this.httpClient.get('http://localhost:4200/assets/images/banner2.jpg', {
          this.httpClient.get( this.assetBaseUrl+this.encryptionservice.get(fileUrl), {
            observe: 'response',
            responseType: 'blob'
          }).subscribe(res => {
            this.fileSaverService.save(res.body, fileName);
          });
          return;
    }

    downloadCertificate(modalRef){
      let item = this.assesmentResult;
      if(item['certificate_url'] != undefined && item['certificate_url'] != null && item['certificate_url'] != ''){
        this.httpClient.get(this.encryptionservice.get(item.certificate_url), {
          observe: 'response',
          responseType: 'blob'
        }).subscribe(res => {
          this.fileSaverService.save(res.body, "Certificate_" + item.certificate_id + ".pdf");
        }, error => {
        });
        return;
      }
    }
    donloadCertificate1(){
      let item = this.courseDetails;
      if(item['certificate_url'] != undefined && item['certificate_url'] != null && item['certificate_url'] != ''){
        this.httpClient.get(this.encryptionservice.get(item.certificate_url), {
          observe: 'response',
          responseType: 'blob'
        }).subscribe(res => {
          this.fileSaverService.save(res.body, "Certificate_" + item.certificate_id + ".pdf");
        }, error => {
        });
        return;
      }
    }

  triggerModal(content) {
    this.modalService.open(content, this.ngbModalOptions).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  startAssesment(chapterDetails,lessonDetails,modalData){
    // this.flag=true;
    if(lessonDetails.is_already_completed==false){
    this.router.navigate(['course/'+this.slug + '/assesment/' +lessonDetails['assesment_details']['assesment_slug']]);
    }
    
    
    // this.lessonDetails = lessonDetails;
    // this.lessonDetails['chapter_name'] = chapterDetails['chapter_name']
    // this.lessonDetails['chapterDetails'] = chapterDetails;
    // if(lessonDetails['assesment_details']['assesment_id'] != undefined && lessonDetails['assesment_details']['assesment_id'] != null){
    //   this.isLoading = true;
    //   this.courseService.getAssesmentDetails(lessonDetails['assesment_details']['assesment_id']).subscribe(response =>{
    //     //this.isLoading = false;
    //     console.log('assesments',response)
    //     if(response){
    //       this.assesmentDetails = response['data'];
    //       if(this.assesmentDetails['exam_assigned_list'].length == 0){
    //         this.toasService.error("No assesment questions found for this assesment.");
    //         return;
    //       }
    //       this.calculateStopTimeInSeconds(this.assesmentDetails);
    //       // this.triggerModal(modalData);
    //       // this.flag=false;
    //     }
    //   })
    // }
  }

  calculateStopTimeInSeconds(assesmentDetails){
    if(assesmentDetails['assesment_time_in_min'] != undefined){
      let elapsedSeconds = Number(assesmentDetails['assesment_time_in_min']) * 60;
      this.config['leftTime'] = elapsedSeconds;
    }
  }

  handleEvent(e: CountdownEvent) {
    this.notify = e.action.toUpperCase();
    if (e.action === 'done') {
     if(this.assesmentTestId != null)
      this.finalAssesmentSubmit();
    }
  }

  takeAssesment(assesmentDetails,modalData){
    //this.isLoading = true;
    this.courseService.startAssessment(this.courseDetails['course_id'],this.lessonDetails['lesson_id'],assesmentDetails['assesment_id']).subscribe(response =>{
      //this.isLoading = false;
      if(response){
        if(response['assesment_test_id'] != undefined){
          this.assesmentTestId = response['assesment_test_id'];
          this.assesmentQuestionIndex = 0;
          this.modalService.dismissAll();
          this.triggerModal(modalData);
        }else
           this.toasService.error("Some Error Occured");
      }
    },
    error => {
      this.handleError(error);
    }
    );
  }

  handleError(error){
    this.isLoading = false;
    if(error['error'] != undefined && error['error']['message'] != undefined){
       this.toasService.error("Error Occured - " + error['error']['message']);
    }
  }

  checkActiveQuestion(currIndex){
    return currIndex == this.assesmentQuestionIndex;
  }

  markLessonAsComplete(lessonId,modalRef){
    this.completedLessonId = lessonId;
    this.triggerConfirmationModal(modalRef);
  }

  markLessonCompleteConfirm(){
    if(this.completedLessonId != null){
      let lessonId = this.completedLessonId;
      this.courseService.markLessonAsComplete(this.courseDetails['course_id'],lessonId).subscribe(response =>{
        if(response){
          this.completedLessonId = null;
          this.toasService.success("Lesson marked as completed");
          this.modalService.dismissAll();
          this.refreshPageDetails();
        }
      },
      error => {
        this.modalService.dismissAll();
        this.completedLessonId = null;
        this.handleError(error);
        this.refreshPageDetails();
      }
      );
    }else{
      this.modalService.dismissAll();
      this.refreshPageDetails();
    }
  }

  closeLessonCompletePopup(){
    this.completedLessonId = null;
    this.modalService.dismissAll();
    let courseDetailsTemp = JSON.parse(JSON.stringify(this.courseDetails));
    this.courseDetails = null;
    this.courseDetails = courseDetailsTemp;
    //this.refreshPageDetails();
  }

  selectChoice(choiceId,choiceIndex,questionId){
      this.selectedChoiceId = choiceId;
      this.assesmentDetails['exam_assigned_list'][this.assesmentQuestionIndex]['choice_list'].forEach((choice)=>{
            choice['selected'] = false;
      })
      this.assesmentDetails['exam_assigned_list'][this.assesmentQuestionIndex]['choice_list'][choiceIndex]['selected'] = true;
      //maintain a local map for answer keys start
      this.quizAnswerMap.set(questionId,this.selectedChoiceId);
  }

  submitSingleAssesment(questionDetail,assesmentResultModal,questionId){
    if(this.selectedChoiceId == -1){
      this.toasService.error("Please choose your answer.");
      return;
    }else{
       let requestBody = {
          exam_assign_id:questionDetail['exam_assign_id'],
          assesment_test_id:this.assesmentTestId,
          ans:this.selectedChoiceId
      };
      this.courseService.submitSingleAssesment(requestBody).subscribe(response =>{
        if(this.assesmentQuestionIndex < this.assesmentDetails['exam_assigned_list'].length){
          this.assesmentQuestionIndex = this.assesmentQuestionIndex + 1;
          this.selectedChoiceId = -1;
          if(this.assesmentQuestionIndex == this.assesmentDetails['exam_assigned_list'].length){
              this.finalAssesmentSubmit();
          }
        }
      },
      error => {
        this.handleError(error);
      })
      }
  }

  checkQuizAnswerMap(questionId,choiceId){
    if(this.quizAnswerMap.get(questionId) != null && this.quizAnswerMap.get(questionId) == choiceId){
      this.selectedChoiceId = this.quizAnswerMap.get(questionId);
      return true;
    }
    return false;
  }

  goToPrevQuestion(){
    if(this.assesmentQuestionIndex > 0){
      this.assesmentQuestionIndex = this.assesmentQuestionIndex - 1;
      let questionBankId = this.assesmentDetails['exam_assigned_list'][this.assesmentQuestionIndex]['question_bank_id'];
      this.selectedChoiceId = this.quizAnswerMap.get(questionBankId);
    }
  }

  closeAssesmentModal(data){
    this.selectedChoiceId = -1;
    this.assesmentQuestionIndex = -1;
    this.assesmentTestId = null;
    this.quizAnswerMap.clear();
    this.modalService.dismissAll();
  }

  continueCourse(data){
    this.selectedChoiceId = -1;
    this.assesmentQuestionIndex = -1;
    this.assesmentTestId = null;
    this.modalService.dismissAll();
    this.quizAnswerMap.clear();
    this.refreshPageDetails();
  }

  retakeAssesment(data,modalRef){
    this.modalService.dismissAll();
    this.selectedChoiceId = -1;
    this.assesmentQuestionIndex = 0;
    this.assesmentTestId = null;
    this.quizAnswerMap.clear();
    this.startAssesment(this.lessonDetails['chapterDetails'],this.lessonDetails,modalRef);
  }

  finalAssesmentSubmit(){
    this.courseService.submitFinalAssesment(this.assesmentTestId).subscribe(response =>{
      this.closeAssesmentModal("");
      this.assesmentResult = response['result'];
      this.triggerModal(this.assesmentResultModal);
      this.refreshPageDetails();
  },
  error => {
    this.refreshPageDetails();
    this.handleError(error);
  })
  }

  triggerConfirmationModal(content) {
    this.modalService.open(content, {size: 'xs',ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  quitAssesment(assesmentResultModal){
    this.finalAssesmentSubmit();
  }

  viewResults(item,lessonDetails ){
    // this.router.navigate(['quiz']);
      //this.router.navigate(['course/'+this.slug + '/assesment/' +lessonDetails['assesment_details']['assesment_slug']]);
       
      this.router.navigate(['course/'+this.slug + '/assesment/' +lessonDetails['assesment_details']['assesment_slug']+'/result/'+lessonDetails['assesment_details']['assessment_test_id']])
  }

  tabSelected(event){
      if(event == 1)
      localStorage.setItem("isCurriculumSelected",'1');
  }

}