import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DashboardService } from 'app/services/dashboard.service';
import { EncryptionService } from 'app/services/encryption.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyProfileService } from 'app/services/my-profile.service';
import { LocalStorageService } from 'app/services/local-storage.service';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    data: any = 0;
    public isLoading: boolean = false;
    public courseData: boolean = false;

    public image_base_url: string;

    private onDestroy$: Subject<void> = new Subject<void>();

    enrolledCount: number = 0;
    certificatesCount: number = 0;
    ongoingCount: number = 0;
    compltedCount: number = 0;
    enRoll: any;
    complete: any
    onGoing: any;
    certiFicate: any;
    assetBaseUrl: string;
    userDetails:any;
    sideNavStatus=false;

    constructor(private dashboardService: DashboardService, private encryptionservice: EncryptionService,
        private router: Router, private toastr: ToastrService,private myprofileservice: MyProfileService,private localStorageService: LocalStorageService) {
    }


    ngOnInit() {
        this.isLoading = true;
        this.getDashbords();
        this.localStorageService.userDetails.subscribe((value) => {});
    }

    profile() {
        this.myprofileservice.myProfile().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
          this.userDetails = res.user_details;
          this.assetBaseUrl = res.user_details.photo_base_url;
          let userDetails = {user_details:this.userDetails,asset_base_url:this.assetBaseUrl};
          localStorage.setItem("qa-userDetails",this.encryptionservice.encryptUsingAES256(JSON.stringify(userDetails)));
          this.localStorageService.setUserDetails("UserDetails");
        }, error => {
          this.isLoading = false;
        });
      }

    enRoleds(limit) {
        if (limit > 0) {
            setTimeout(() => {
                this.enRoll = setInterval(() => {
                    this.enrolledCount++;
                    if (this.enrolledCount == limit) {
                        clearInterval(this.enRoll);
                    }
                }, 10)
            }, 1000);
        }
    }

    certificates(limit) {
        if (limit > 0) {
            setTimeout(() => {
                this.certiFicate = setInterval(() => {
                    this.certificatesCount++;
                    if (this.certificatesCount == limit) {
                        clearInterval(this.certiFicate);
                    }

                }, 10)
            }, 1000);
        }

    }


    ongoingCourse(limit) {
        if (limit > 0) {
            setTimeout(() => {
                this.onGoing = setInterval(() => {
                    this.ongoingCount++;
                    if (this.ongoingCount == limit) {
                        clearInterval(this.onGoing);
                    }

                }, 10)
            }, 1000);
        }
    }


    completed(limit) {
        if (limit > 0) {
            setTimeout(() => {
                this.complete = setInterval(() => {
                    this.compltedCount++;
                    if (this.compltedCount == limit) {
                        clearInterval(this.complete);
                    }

                }, 10)
            }, 1000);
        }


    }


    getDashbords() {
        
        this.dashboardService.myDashbaord().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
            this.data = res.data
            // console.log('dasbords', this.data)
            this.image_base_url = this.encryptionservice.get(res.data.course_banner_base_url);
            this.isLoading = false;
            this.enRoleds(this.data.course_tiles.course_enrolled);
            this.certificates(this.data.course_tiles.certificate_earned);
            this.completed(this.data.course_tiles.completed_course);
            this.ongoingCourse(this.data.course_tiles.ongoing_course);
            window.scroll(0,0);
            this.profile();

        }, error => {
            this.isLoading = false;
            // console.log(error.error.message)
            this.toastr.error(error.error.message)
        });
    }

    startedCourse() {
        this.router.navigate(['courses']);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}