<app-spinner *ngIf="isLoading"></app-spinner>
<div class="container-fluid py-3" *ngIf="!isLoading">
   <h1  class="heaading-fontsize"*ngIf="data.user_name !== 'loading'"><b>{{data?.wish_string}} {{data?.user_name}}</b></h1>
   <h1 *ngIf="data.user_name == 'loading'">Loading...</h1>
   <h6><b>{{data?.date }}</b></h6>
   <div class="row gx-5 my-3 py-4">
      <div class="col-md-10 col-lg-10 col-sm-12 col-12 rounded ">
         <div class="row gx-4 quiz font_content jsutifiy-content-center text-center">
            <div class="col-md-3 col-lg-3  col-sm-12 col-12 pointer-none">
               <div class="card" style="background-color:  #fff8dd; border: 2px dashed #efe7cb !important;">
                  <img class="card-img-top img-fluid img-responsive" src="./assets/svg/enroll.svg" alt="Dashboard_pic">
                  <div class="card-body">
                     <h5 class="card-title">{{enrolledCount}} </h5>
                     <p class="card-text ">Enrolled Course</p>
                  </div>
               </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-12 pointer-none">
               <div class="card" style="background-color: #f1faff; border: 2px dashed #d1e0e9 !important;">
                  <img class="card-img-top img-fluid img-responsive" src="./assets/svg/_Ongoing.svg"
                     alt="Dashboard_pic">
                  <div class="card-body">
                     <h5 class="card-title">{{ongoingCount}}</h5>
                     <p class="card-text ">Ongoing Course</p>
                  </div>
               </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-12 pointer-none">
               <div class="card" style="background-color:#fff5f8; border: 2px dashed #ddc5cc !important;">
                  <img class="card-img-top img-fluid img-responsive" src="./assets/svg/_Completed.svg"
                     alt="Dashboard_pic">
                  <div class="card-body">
                     <h5 class="card-title">{{compltedCount}}</h5>
                     <p class="card-text ">Completed Courses</p>
                  </div>
               </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-12 pointer-none">
               <div class="card" style="background-color: #f8f5ff; border: 2px dashed #d0cbdd !important;">
                  <img class="card-img-top img-fluid img-responsive" src="./assets/svg/Certificate.svg"
                     alt="Dashboard_pic">
                  <div class="card-body">
                     <h5 class="card-title">{{certificatesCount}}</h5>
                     <p class="card-text">Certificates Earned</p>
                  </div>
               </div>
            </div>
         </div>
         <br />

         <div class="container side_content rounded py-3" *ngIf="data?.recent_courses.length == 0">
             <div class="row">
               <div class="col-md-2 ">
                  <img src="./assets/images/EtonCollegeLogo.png" class="img-fluid img-responsive w-100 my-3"
                     alt="image">
               </div>
               <div class="col-md-10">
                  <h3 class="font-weight-bold text-white text-start font-subheading">Welcome To Eton College !</h3>
                  <p class=" text-white  new-1 body-font" >
                      Learn coding fundamentals with a top-rated platform. With our customized learning paths, coding for kids is a lot of fun. 
                      Join us as we explore the process through which young individuals learn to code spontaneously.
                  </p>
                  <div class="text-end new">
                     <button class="btn btn-outline-light btn-md bg-white text-dark font-weight-bold font-change"
                        (click)="startedCourse()"> Let's Get started</button>
                  </div>
               </div>
            </div>
         </div>
         <br />
         <div *ngIf="data?.recent_courses.length > 0">
            <h2 class="mx-1 heaading-fontsize py-3"> <b>Recently Viewed</b></h2>
            <div class="row py-4 shadow p-3 mb-5 bg-white rounded mx-2"
               *ngFor="let item of data.recent_courses; let i = index">

               <div class="col-md-6 col-lg-6 col-sm-6 col-6 rounded ">
                  <a [routerLink]="'/course/'+item.course_slug" class="img-fluid img-responsive">
                     <img [src]="item.course_banner | urlDecryption: image_base_url" class="course-img" alt="image">
                  </a>
               </div>
               <div class="col-md-6 col-lg-6 col-sm-6 col-6 rounded ">
                  <div class="">
                     <div class="d-flex bd-highlight">
                        <div class="p-2 flex-fill bd-highlight">
                           <h5 class="font-weight-bold py-2 course-title font-subheading ">
                              <a [routerLink]="'/course/'+item.course_slug">{{item.course_title}} </a>
                           </h5>
                           <div class="jsutifiy-content-around text_descrption body-font"  [innerHtml]="item?.course_about"></div>
                           <br>
                           <div class="progress">
                              <div class="progress-bar bg-danger" role="progressbar"
                                 [style]="'width:'+item.course_completed_percent+'%;'" aria-valuenow="10"
                                 aria-valuemin="0" aria-valuemax="100">{{item?.course_completed_percent}}%</div>
                           </div>
                           <br />
                           <div class=" d-flex"  style="justify-content: space-between">
                              <div class="col-md-6 col-lg-6 py-2 position-change ">
                                 <p  class="position-font">{{item?.course_completed_percent}}% Completed</p>
                              </div>
                              <div class="col-md-6 col-lg-6 position-change1">
                                 <button class="btn btn-danger lms-button text-bold position-font"
                                    [routerLink]="'/course/'+item.course_slug">
                                    Continue
                                    Learning</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>

      </div>

      <div style="background-image: url('../../../../assets/images/BoardImage.png')"
         class="col-md-2 col-lg-2 col-sm-12 col-12 gx-5 side_content_banner rounded jsutifiy-content-center text-center baner_img">
         <img class="img-fluid img-responsive py-4" src="./assets/images/EtonCollegeLogo.png" alt="Dashboard_pic">
        <div class="color-hover">
         <a   href="https://etoncollege.ca/" target="_blank">
            <h3 class="side_text">Go back to
               <br> Eton College</h3>
          </a>
        </div>
        
      </div>
   </div>
</div>
<ngx-scrolltop></ngx-scrolltop>