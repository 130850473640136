import { Component, OnInit, OnDestroy } from '@angular/core';
import { MyProfileService } from 'app/services/my-profile.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  public assetBaseUrl: string;
  userDetails:any;
  isLoading: boolean = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  
  constructor(private myprofileservice: MyProfileService) { 
  }

  ngOnInit(): void {
      this.profile();
  }
  profile() {
    this.isLoading = true;
    this.myprofileservice.myProfile().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      // console.log('myprofile.......',res)
      this.userDetails = res.user_details;
      this.assetBaseUrl = res.user_details.photo_base_url;
      this.isLoading = false;
      window.scroll(0,0);
    }, error => {
      this.isLoading = false;
    });
  }
}
