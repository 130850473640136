import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { CourseService } from 'app/services/course.services';
import { EncryptionService } from 'app/services/encryption.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit, OnDestroy  {

  private onDestroy$: Subject<void> = new Subject<void>();

  public courses: any [] = [];
  public image_base_url: string;
  public isLoading: boolean = false;

  constructor(private courseService: CourseService, private encryptionservice:EncryptionService) { }

  ngOnInit(): void {
    this.getAllCourses();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getAllCourses() {
    this.isLoading = true;
    this.courseService.getAllCourses().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.image_base_url = this.encryptionservice.get(res.course_banner_base_url);
      this.courses = res.data;
      this.isLoading = false;
      window.scroll(0,0);
    }, error => {
      this.isLoading = false;
    });
  }
}
