<div *ngIf="(!isError && !isLoading)">
  <div class="container-fluid bradcum  py-5 text-white">
    
    <div class="page-title-content">
      <ul>
        <li><a id="dashboard" (mouseover)="addHiglightClass('dashboard')" (mouseout)="removeHiglightClass('dashboard')"
            routerLink="/dashboard">Dashboard</a></li>
        <li><a id="mycourses" (mouseover)="addHiglightClass('mycourses')" (mouseout)="removeHiglightClass('mycourses')"
            routerLink="/courses">My Courses</a></li>
        <!-- <li>{{ courseDetails.course_title }}</li> -->
        <li> <a id ="course" (mouseover)="addHiglightClass('course')" (mouseout)="removeHiglightClass('course')" routerLink="/course/{{courseDetails.course_slug}}">{{courseDetails.course_title}}</a>
        <li><a id="lesson" (mouseover)="addHiglightClass('lesson')"
            (mouseout)="removeHiglightClass('lesson')">{{lesson_data?.lesson_details?.lesson_name}}</a></li>
      </ul>
    </div><br>

    <span style="color:  #d2e6f3;">Module {{lesson_data?.chapter_details?.current_chapter}} of
      {{lesson_data?.chapter_details?.total_chapters}}</span>
    <br>
    <h2  class="heaading-fontsize"style="color:white; margin-top: 10px; "><b>{{lesson_data?.chapter_details?.chapter_name}}</b></h2>
    <div  *ngIf="lesson_data?.chapter_details?.chapter_url" class="tag  pointer-hover ">
      <img class="active img-hover" style=" z-index:99; width: 30px; height: 30px;"
        src="../../../../assets/images/Agent_LMS/Video.svg" (click)="playIntroVideo(modalDataForChapterHeaders)"> Play
      Intro Video

    </div>
  </div>
 

  <div class="pt-3 pb-3">

    <div *ngIf="lesson_data?.course_details?.course_intro" class="accordion bg-light"
      id="accordionPanelsStayOpenExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <a class="accordion-button collapsed text-dark body-font" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne">
            Introduction to the Course
          </a>
        </h2>

        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse  py-3"
          aria-labelledby="panelsStayOpen-headingOne">
          <div class="accordion-body bg-light">
            <span>
              <div class="textchange body-font" [innerHtml]="lesson_data?.course_details?.course_intro"></div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="lesson_data?.chapter_details?.chapter_description" class=" card bg-light " style=" margin-top: 10px;">
    <div class="textchange body-font" style="padding: 10px;" [innerHtml]="lesson_data?.chapter_details?.chapter_description"></div>
  </div>
  <div>
    <br>
    <!-- <div class="d-flex previous-next">
      <div>
        <button class="previous btn-pre" *ngIf="showPrevButton" (click)=" previousLesson()">
          <span class="previous fa fa-caret-left"></span>&nbsp;&nbsp;Previous Lesson
        </button>
        
      </div>
      <div>
        <button (click)="nextLesson()" *ngIf="showNextButton" class="next btn-pre">Next Lesson &nbsp;<span
          class=" btn-pre fa fa-caret-right"></span></button>
      </div>
    </div>
    <br>
     -->
     <div class="scroll-backgorund " 
          (scroll)="onscroll()"
          [ngClass]="scrollfixed? 'scroll_fixed':'scroll_nofixed'" 
        
          >
          <div class="d-flex" style="justify-content: space-between;" (scroll)="onscroll()"
          [ngClass]="scrollfixed? '  scroll-color-bg':' scroll-color-bg-notfixed'">
            <div>
              <button class="previous btn-pre" *ngIf="showPrevButton" (click)=" previousLesson()">
                <span class="previous"><i class="fa fa-caret-left"></i>  Previous Lesson</span>
              </button>
            </div>
            <div>
              <button (click)="nextLesson()" *ngIf="showNextButton" class="next btn-pre"><span class="previous">Next Lesson  <i class="  fa fa-caret-right"></i></span>
                </button>
            </div>
            </div>
          </div><br>
    <div style="color:  #105F96 ; font-size: 16px;">Lesson {{lesson_data?.lesson_details?.current_lesson}} of
      {{lesson_data?.lesson_details?.total_lessons}}</div>
    
    <div class="row" >
      <div class="col-md-6">
        <h2 class="heaading-fontsize py-2"><b>{{lesson_data?.lesson_details?.lesson_name}}</b></h2>
      </div>
      <div class="col-md-6">
        <div class="d-flex">
          <div >
            <button *ngIf="chapter_ppt_flag" class="btn btn-danger slides_btn" (click)="viewSlide(slidesModal)"> <i class="fa fa-eye" aria-hidden="true"></i> Slides
               </button>
               <button *ngIf="lesson_ppt_flag" class="btn btn-danger slides_btn" (click)=" viewLessonSlide(lessonSlidesModal)"> <i class="fa fa-eye" aria-hidden="true"></i> Slides
               </button>&nbsp;&nbsp;
            
        <button [disabled]="lesson_data.lesson_details?.is_already_completed"
          (click)="markLessonAsComplete(lessonDetails.lesson_details?.lesson_id,confirmationLessonCompleteModal)"
          [ngClass]="[(lesson_data.lesson_details.is_already_completed != undefined && lesson_data.lesson_details.is_already_completed) ? 'btn btn-danger complete-btn ' : 'btn btn-danger lms-button']">
          <i *ngIf="(lesson_data.lesson_details.is_already_completed != undefined && lesson_data.lesson_details.is_already_completed)"
            class="fa fa-check msm" aria-hidden="true"></i>&nbsp;{{lesson_data.lesson_details.is_already_completed !=
          undefined && lesson_data.lesson_details.is_already_completed ? 'Completed' : 'Mark as Complete'}}</button>
        </div>&nbsp;&nbsp;&nbsp;
        <div >
        <button class="btn btn-danger lms-button "
          routerLink="/course/{{courseDetails.course_slug}}">Back to Course</button>
          </div>
        </div>
    </div>
    <!-- chapter slides modal -->

<ng-template #slidesModal let-modal>
  <div class="modal-header py-2">
<h4 class="modal-title" id="modal-basic-title">Slides</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
   </div>
  <div class="modal-bod justify-content-center bg-light">
      <iframe   [src]= "coursePpt | safeHtml " width='1140px' height='570px' frameborder='0'></iframe>
      	<!-- <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx'  width='1140px' height='570px' frameborder='0'></iframe> -->
      <br>
  </div>
 </ng-template>

 <!-- lesson slide modal -->

 <ng-template #lessonSlidesModal let-modal>
  <div class="modal-header py-2">
<h4 class="modal-title" id="modal-basic-title">Slides</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
   </div>
  <div class="modal-bod justify-content-center bg-light">
      <iframe   [src]= "lessonPpt | safeHtml " width='1140px' height='570px' frameborder='0'></iframe>
      <br>
  </div>
 </ng-template>

 <!-- quiz slides modal -->

<ng-template #quizSlideModal let-modal>
  <div class="modal-header py-2">
<h4 class="modal-title" id="modal-basic-title">Quiz</h4>
      <!-- <button type="button" class="close" aria-label="Close" >
          <span aria-hidden="true">×</span>
      </button> -->
   </div>
  <div class="modal-bod justify-content-center bg-light">
      <!-- <iframe [src]= "coursePpt | safeHtml" id = "myiFrame"  width='1140px' height='570px' frameborder='0' onload="displayMessage()"></iframe> -->
     <div class="mx-2 py-2">
      <p> 1. HTML stand for?   </p>
          <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                  a. HighText Machine Language </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                  b. HyperText and links Markup Language  </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                  c. HyperText Markup Language  </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                  d. None of these </label>
            </div>
                 <div class="py-2 text-center">
      <button type="button" class="btn btn-danger bt-md" (click)="modal.dismiss()">Submit Quiz</button>
      </div>
  </div>
</div>
 </ng-template>

    

    <div class=""
      *ngIf="lesson_data?.lesson_details?.lesson_host == 'AUDIO_SELF_HOST' || (lesson_data?.lesson_details?.lesson_host == '' && lesson_data?.lesson_details?.duration_in_min == 0)">
      <br>

      <div class="padding-lr-30">
        <div class="row">
          <div class="col-lg-12 col-md-12 scroll-audio" *ngIf="lesson_data?.lesson_details?.lesson_host == 'AUDIO_SELF_HOST'">
            <audio oncontextmenu="return false;" style="width: 100%;" controls controlsList="nodownload">
              <source [src]="lesson_data?.lesson_details?.lesson_url | urlDecryption:audioBaseUrl" type="audio/mp3">
            </audio><br><br>
          </div>

          <div class="col-lg-12 col-md-12">
            <div class="course-d">
              <div class="course-desc audio-section">
                <span>
                  <div  *ngIf="lesson_data?.lesson_details?.lesson_host == 'AUDIO_SELF_HOST'" class="textchange body-font" [innerHtml]="lesson_data?.lesson_details?.lesson_description"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<div>
  <div  *ngIf="lesson_data?.lesson_details?.lesson_host == null || lesson_data?.lesson_details?.lesson_host == 'AUDIO'" class="textchange body-font" [innerHtml]="lesson_data?.lesson_details?.lesson_description"></div>
</div>

    <div
    *ngIf="lesson_data?.lesson_details?.lesson_host == 'VIDEO' || lesson_data?.lesson_details?.lesson_host == 'VIMEO' ||  (lesson_data?.lesson_details?.lesson_host == '' && lesson_data?.lesson_details?.duration_in_min == 0)">
    <br>

    <div class="padding-lr-30">
      <div class="row">
        <div class="col-lg-12 col-md-12 scroll-audio" *ngIf="lesson_data?.lesson_details?.lesson_host != 'VIMEO'">
          <vm-player theme="light" >
            <vm-video cross-origin [poster]="lesson_data?.lesson_details?.lesson_thumb_image | urlDecryption :assetBaseUrl">
              <source [src]="lesson_data?.lesson_details?.lesson_url | videoEncrypt: assetBaseUrl" type="video/mp4" />
            </vm-video>
            <vm-default-ui>
              <vm-default-controls></vm-default-controls>
            </vm-default-ui>
        </vm-player>
        </div>
        <div class="col-lg-12 col-md-12" *ngIf="lesson_data?.lesson_details?.lesson_host == 'VIMEO'">
          <app-vimeo-video [fileUrl]="lesson_data?.lesson_details?.lesson_url" [assestBaseUrl]="assetBaseUrl"  ></app-vimeo-video>
      </div>

        <div class="col-lg-12 col-md-12">
          <div class="course-d">
            <div class="course-desc audio-section">
              <span>
                <br>
                <br>
                <div class="textchange body-font" [innerHtml]="lesson_data?.lesson_details?.lesson_description"></div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</div>
</div>
<br>
<br>

<!-- intro video modal -->

<ng-template #modalDataForChapterHeaders let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{lesson_data?.chapter_details?.chapter_name}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="lesson_data?.chapter_details?.chapter_host != 'VIMEO'">

    <vm-player theme="light">

      <vm-video [poster]="lesson_data.chapter_details.chapter_thumb_image | urlDecryption :assetBaseUrl">
        <source [src]="lesson_data.chapter_details.chapter_url | urlDecryption: assetBaseUrl" type="video/mp4" />
      </vm-video>

      <vm-default-ui controls="true">
        <vm-default-controls>
        </vm-default-controls>
      </vm-default-ui>
    </vm-player>
  </div>
  <div class="modal-body" *ngIf="lesson_data?.chapter_details?.chapter_host == 'VIMEO'">
       <app-vimeo-video [fileUrl]="lesson_data.chapter_details.chapter_url" [assestBaseUrl]="assetBaseUrl"  ></app-vimeo-video>
  </div>
</ng-template>

<!-- confirmation modal -->


<ng-template #confirmationLessonCompleteModal let-modal>
  <div class="modal-header py-2">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body justify-content-center bg-light">
    <p class="mx-3 py-3">Are you sure you want to mark this lesson as complete ?</p>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeLessonCompletePopup();  scrolltop()">No</button>
    <button type="button" class="btn btn-primary" (click)="markLessonCompleteConfirm();scrolltop()">Yes</button>
  </div>
</ng-template>

<app-spinner *ngIf="isLoading"></app-spinner>
<div class="container-fluid" *ngIf="isError">
  <div>
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="error-content">
            <h3 class="font-subheading">Warning</h3>
            <p class="body-font">{{isErrorStatus}}</p>
            <br>
            <a (click)="goBack()" class="btn btn-danger home-btn">Go Back</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-scrolltop></ngx-scrolltop>