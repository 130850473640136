import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { AdminEncryptionService } from './admin-encryption.service';

@Injectable({
  providedIn: 'root'
})
export class AdminApiHandleService {

  APIURL: string = environment.apiURL + 'api/admin/';
  ADMINAPIURL: string = environment.adminApiURL + 'api/admin/';
  ipaddress: string = '0.0.0.0';
  constructor(private http: HttpClient,
    private adminEncryptionService:AdminEncryptionService) { }

  getIPAddress() {
    return this.http.get<any>("https://api.ipify.org/?format=json", {});
  }

  setIpArress() {
    if (this.ipaddress === null) {
      this.getIPAddress().subscribe(res => {
        this.ipaddress = res.ip;
      });
    }
  }

  apipost(data) {
    return this.http.post<any>(this.ADMINAPIURL + "login", data)
  }

  myDashbaord() {
    return this.http.get<any>(this.APIURL + "my-dashboard")
  }

  forgotPassword(txt_email: any) {
    return this.http.post<any>(this.ADMINAPIURL + "sendresetlink", txt_email)

  }
  resetPassword(body: any) {
    return this.http.post<any>(this.ADMINAPIURL + "reset-password", body)

  }

  getCourseList(body: any) {
    return this.http.post<any>(this.ADMINAPIURL + "/get-all-course", body)
  }

  logout() {
    return this.http.get<any>(this.ADMINAPIURL + "logout")
  }

  // **Question group**
  getQuestionGroup(body: any) {
    return this.http.post<any>(this.ADMINAPIURL + "get-question-group", body)
  }

  createQuestionGroup(body: any) {
    return this.http.post<any>(this.ADMINAPIURL + "create-question-group", body)
  }

  showQuestionGroup(id: any) {
    return this.http.get<any>(this.ADMINAPIURL + "question-group/" + id)
  }
  RemoveQuestionGroup(id: any) {
    return this.http.get(this.ADMINAPIURL + 'delete-question-group/' + id);
  }

  apiPost(url: any, body: any) {
    return this.http.post(this.ADMINAPIURL, url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  apiGet(url: any) {
    return this.http.get(this.ADMINAPIURL, url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveCourse(data: any) {
    return this.http.post<any>(this.ADMINAPIURL + "create-course", data)
  }
  saveChapter(data: any) {
    return this.http.post<any>(this.ADMINAPIURL + "create-chapter", data)
  }
  saveLesson(data: any) {
    return this.http.post<any>(this.ADMINAPIURL + "create-lesson", data)
  }
  assignQuiz(data: any) {
    return this.http.post<any>(this.ADMINAPIURL + "assign-quiz", data)
  }

  getquestionbankList(body:any){
    return this.http.post<any>(this.ADMINAPIURL+"get-question-bank",body)
  }
  RemoveQuestionBank(id: any) {
    return this.http.get(this.ADMINAPIURL + 'question-bank-delete/' + id);
  }


  getAllCoures(id:any){
    return this.http.get<any>(this.ADMINAPIURL +"get-course-details/"+ id);
  }

  deleteCoures(id:any){
    return this.http.get<any>(this.ADMINAPIURL +"/course-delete/"+ id);
  }

  getChapterList(id:any){
    return this.http.get<any>(this.ADMINAPIURL +"get-course-details/"+ id);

}

getLessonList(id:any){
  return this.http.get<any>(this.ADMINAPIURL +"get-course-details/"+ id);
}
 refreshToken() {
    let token = localStorage.getItem('msm-admin-auth-storage') ? JSON.parse(this.adminEncryptionService.decryptUsingAES256(localStorage.getItem('msm-admin-auth-storage'))).refresh_token:null;
   if (token == null){
    return
   }
    this.http.post<any>(this.APIURL + "refresh-token", {refresh_token: token}).subscribe(res => {
        localStorage.setItem('msm-admin-auth-storage', this.adminEncryptionService.encryptUsingAES256(JSON.stringify(res.token_details)));
    }, error => {
        console.log("Logged out")
        localStorage.removeItem('msm-admin-auth-storage');
    });
}

refreshSourceToken(sourceToken) {
  return this.http.post<any>(this.APIURL + "refresh-token", {refresh_token: sourceToken});
}


}
