<div class="page-title-area" *ngIf="!isLoading && courseEnabled">
    <div class="container-fluid">
        <div class="page-title-content">
            <ul>
                <li><a class="font-change" routerLink="/dashboard">Dashboard</a></li>
                <li><a class="font-change" routerLink="/courses">My Courses</a></li>
                <li>{{ courseDetails.course_title }}</li>
            </ul>
        </div>
    </div>
</div>
<app-spinner *ngIf="isLoading"></app-spinner>
<div class="courses-details-area pt-50 pb-100" *ngIf="!isLoading">
    <div class="container-fluid" *ngIf="!courseEnabled">
        <!-- <div class="card">
            <div class="card-body">
                <h5 class="card-title text-bold">Warning</h5>
                <p class="card-text ">{{courseErrMsg}}</p>
                <br>
                <a routerLink="/courses" class="default-btn certificate-button1">Go Back</a>
            </div>
        </div> -->
        <div >
            <div class="d-table">
              <div class="d-table-cell">
                <div class="container">
                  <div class="error-content">
                    <h3 class="font-subheading">Warning</h3>
                    <p class="body-font card-text">{{courseErrMsg}}</p>
                    <br>
                    <a routerLink="/courses" class="default-btn certificate-button1" class="btn btn-danger home-btn">Go Back</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    <div class="container-fluid" *ngIf="courseEnabled">
        <div class="row">
            <div class="col-lg-8 col-md-12 col-xs-12 col-sm-12 ">
                <div class="courses-details-image-style-two">
                    <h3  class="heaading-fontsize"style="font-weight: 700; margin-bottom: 30px;">{{ courseDetails.course_title }}</h3>
                    <div class="div1">
                        <div class="tag  pointer-hover" *ngIf="courseDetails.is_show_overview_video!=0">
                            <img (click)="playIntroVideo(courseDetails,introVideoModalData)" class="active img-hover"
                                style=" z-index:99; width: 60px; height: 60px;"
                                src="../../../../assets/images/Agent_LMS/Video.svg">
                            <video style="width:100% ; height: 100%; border-radius: 5px;">
                                <source src="">
                            </video>

                        </div>
                        <img [src]="courseDetails.course_banner | urlDecryption:assetBaseUrl" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-xs-12 col-sm-12 ">
                <div class="courses-sidebar-information">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                                <span class="body-font"> Duration
                                    <img class="active img-hover"
                                        style=" z-index:99; width: 25px; height: 20px;right: 90px;top: 2px;  color: #252525; font-size: 16px;"
                                        src="../../../../assets/images/Agent_LMS/Duration.svg"></span>
                                {{durationConvert('total_duration_in_min')}} Hours
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                                <span class="body-font"> Lessons
                                    <img class="active img-hover"
                                        style=" z-index:99; width: 25px; height: 20px;right: 90px;top: 1px;  color: #252525;font-size: 16px;"
                                        src="../../../../assets/images/Agent_LMS/Lessons.svg"></span>
                                {{getCourseInclude('total_lesson')}}
                            </div>
                        </li>
                        <li *ngIf="getCourseInclude('total_agent_completed')>1000">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="body-font"><i class="flaticon-web"></i> Agents completed</span>
                                {{getCourseInclude('total_agent_completed')}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                                <span class="body-font"> Access
                                    <img class="active img-hover"
                                        style=" z-index:99; width: 25px; height: 20px;right: 100px;top: 1px ;  color: #252525; font-size: 16px;"
                                        src="../../../../assets/images/Agent_LMS/Access.svg"></span>
                                {{getCourseInclude('access')}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                                <span class="body-font"> Assessment Available
                                    <img class="active img-hover"
                                        style=" z-index:99; width: 25px; height: 20px;right: 200px;top: 2px; color: #252525; font-size: 16px;"
                                        src="../../../../assets/images/Agent_LMS/Assesment.svg"></span>
                                {{getCourseInclude('is_assesment_available') ? 'Yes':'No'}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                                <span class="body-font"> Certification Available
                                    <img class="active img-hover"
                                        style=" z-index:99; width: 25px; height: 20px;right: 200px;top: 2px; color: #252525;"
                                        src="../../../../assets/images/Agent_LMS/Certificate.svg"></span>
                                {{getCourseInclude('is_certificate_available') ? 'Yes':'No'}}
                            </div>
                        </li>
                    </ul>
                    <div *ngIf="this.courseDetails.course_completed_percent !== 0"
                        class="d-flex justify-content-between pt-3 pb-3 course-progress ">
                        <div>
                            <p class="body-font">Course Progress</p>
                            <p>{{getCourseInclude('total_completed_lessons')}}/{{getCourseInclude('total_lesson')}}</p>
                        </div>
                        <div>
                            <p class="body-font course-progress ">{{this.courseDetails.course_completed_percent}}% Complete</p>
                        </div>
                    </div>
                    <div *ngIf="this.courseDetails.course_completed_percent !== 0" class="progress course-progress">
                        <div class="progress-bar bg-danger" role="progressbar"
                            [style]="'width:'+this.courseDetails.course_completed_percent+'%;'" aria-valuenow="10"
                            aria-valuemin="0" aria-valuemax="100">{{this.courseDetails.course_completed_percent}}%</div>
                    </div>
                    <div class="certificate-Button " *ngIf="this.courseDetails.course_completed_percent===100">
                        <button class="certificate-button1" (click)="donloadCertificate1()">Get
                            Certificate<span></span></button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-8 col-md-12 col-xs-12 col-sm-12 ">
                <div class="courses-details-desc ">
                    <ngx-tabset (onSelect)="tabSelected($event)">
                        <ngx-tab tabTitle="Overview" >
                            <div class="courses-overview">
                                <h3 class="font-subheading">Course Description</h3>
                                <div class="body-font" [innerHtml]="courseDetails.course_about"></div>
                            </div>
                        </ngx-tab>
                        
                        <ngx-tab tabTitle="Curriculum" [active]="curriculumSelected">
                            <div *ngFor="let item of courseDetails.chapters" class="courses-curriculum">
                                <div class="d-flex justify-content-between">
                                    <h3 class=" font-subheading">{{ item.chapter_name }}</h3>
                                    <span *ngIf="item.chapter_url" class=" video-icon pointer-hover"
                                        (click)="playChapter(item,modalDataForChapterHeaders)">
                                        <img class="img-hover" src="../../../../assets/images/Agent_LMS/Video.svg"
                                            alt="">
                                    </span>
                                </div>
                                <ul>

                                    <li *ngFor="let lesson of item.lessons; let lessonIndex = index;">
                                       
                                  <a  *ngIf="(lesson.is_assesment == false)"  
                                
                                            class="play-icon popup-youtube  d-flex" >
                                    <!-- <i class="fa fa-check" ngbTooltip="{{lesson.lesson_name}}"  ></i> -->
                                        
                                            <input class="form-check-input size-change" type="checkbox" title="Mark as Complete"
                                       
                                            [id]="lesson.lesson_id" [checked]="lesson.is_already_completed"
                                                [disabled]="lesson.is_already_completed || (lesson.is_lock != undefined && lesson.is_lock == true)"
                                                (click)="markLessonAsComplete(lesson.lesson_id,confirmationLessonCompleteModal)">
                                                <i class="ml-30-auto"  [ngClass]="[(lesson.lesson_host == 'VIDEO') ? 'flaticon-play mr-5 mt-0' : lesson.lesson_host == 'AUDIO_SELF_HOST' ? 'fa fa-file-audio-o mr-5 mt-1 ': 'fa fa-file-pdf-o mr-5 mt-1']"></i>
                                                <span class="courses-name  courses-name1 ml-30-auto body-font">
                                                {{lesson.lesson_name.trim() }}
                                            </span>
                                            <div class="courses-meta courses-meta1">
                                                <span class="duration"
                                                    *ngIf="getDuration(lesson.duration_in_min) !== '0 min'">{{getDuration(lesson.duration_in_min)}}</span>
                                                <span class="status pointer-hover"
                                                    (click)="(lesson.is_lock != undefined && !lesson.is_lock) ? playLessonVideo(item,lesson,modalData) : false"><i
                                                        *ngIf="(lesson.is_lock != undefined && lesson.is_lock == true)"
                                                        class="fa fa-lock" aria-hidden="true"></i>{{(lesson.is_lock !=
                                                    undefined && lesson.is_lock == true) ? '':'View'}}</span>
                                            </div>
                                        </a>

                                        <a *ngIf="(lesson.lesson_host == null && lesson.is_assesment !== false)"
                                            class="play-icon popup-youtube d-flex justify-content-between align-items-center">
                                            <input class="form-check-input" type="checkbox" value=""
                                                [id]="lesson.lesson_id" [checked]="lesson.is_already_completed"
                                                [disabled]="(lesson.lesson_host == null && lesson.is_assesment) || lesson.is_already_completed">
                                            <span class="courses-name ml-30-auto body-font"><i class="flaticon-quiz mr-5"></i>
                                                {{lesson['assesment_details']['assesment_title']}}</span>
                                            <div class="courses-meta courses-meta1">
                                                <span *ngIf="!lesson.is_already_completed"
                                                    class="duration">{{lesson['assesment_details'] != undefined ?
                                                    getDuration(lesson['assesment_details']['assesment_time_in_min']) :
                                                    ''}}</span>
                                                    <span class="status pointer-hover" *ngIf="(lesson.assesment_details.is_failed == false)"
                                                    (click)="(lesson.is_lock != undefined && !lesson.is_lock) ? 
                                                    ((lesson.is_already_completed != undefined && !lesson.is_already_completed) ? startAssesment(item,lesson,assesmentModal) 
                                                    : viewResults(item,lesson)) : false"><i
                                                        *ngIf="(lesson.is_lock != undefined && lesson.is_lock == true)"
                                                        class="fa fa-lock" aria-hidden="true"></i> 
                                                        {{(lesson.is_lock != undefined && lesson.is_lock == true) ? '' :(lesson.is_already_completed ? 'View' : 'Start')}}</span>


                                                        <span class="status pointer-hover" (click)="viewResults(item,lesson)" *ngIf="(lesson.assesment_details.is_failed)">Retake</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ngx-tab>

                        <ngx-tab *ngIf="courseDetails.course_material.length > 0" tabTitle="Materials" class="body-font">
                            <div class="courses-curriculum">
                                <h3>Course Materials</h3>
                                <ul>
                                    <li *ngFor="let item of courseDetails.course_material">
                                        <a class="popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">{{item.material_name}}</span>
                                            <div class="courses-meta">
                                                <span class="status pointer-hover"
                                                    (click)="downloadFile(item.material_name,item.material_file)">Download</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ngx-tab>
                        
                    </ngx-tabset>
                </div>
            </div>

        </div>
    </div>
   
</div>


<ng-template #modalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{lessonDetails.chapter_name}} - {{lessonDetails.lesson_name}}
        </h4>
        <button *ngIf="!lessonDetails.is_already_completed" class="my-sm-0 markascompleted"
            (click)="markLessonAsComplete(lessonDetails.lesson_id,confirmationLessonCompleteModal)">Mark as
            Complete</button>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="lessonDetails.lesson_host == 'VIDEO'">
       
    <vm-player theme="light" >
            <vm-video cross-origin [poster]="lessonDetails.lesson_thumb_image | urlDecryption: assetBaseUrl">
            <source [src]="lessonDetails.lesson_url | urlDecryption: assetBaseUrl" type="video/mp4" />
        </vm-video>
        <vm-default-ui>
            <vm-default-controls></vm-default-controls>
       </vm-default-ui>
    </vm-player>


    </div>
    <!--modal box for audio popup-->

    <div class="modal-body"
        *ngIf="lessonDetails.lesson_host == 'AUDIO' || (lessonDetails.lesson_host == '' && lessonDetails.duration_in_min == 0)">
        <br>
        <div class="container padding-lr-30">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 " *ngIf="lessonDetails.lesson_host == 'AUDIO'">
                    <audio oncontextmenu="return false;" style="width: 100%;" controls controlsList="nodownload">
                        <source [src]="lessonDetails.lesson_url | urlDecryption: assetBaseUrl" type="audio/mp3">
                    </audio>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
                    <div class="course-d">
                        <div class="course-desc audio-section">
                            <span>
                                <div [innerHtml]="lessonDetails.lesson_description"></div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--intro video pop-->
<ng-template #introVideoModalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{courseDetails.course_title}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">

        <div *ngIf="courseDetails.overview_video_host == 'VIMEO'">
            <app-vimeo-video [fileUrl]="courseDetails.overview_video_link " [assestBaseUrl]="assetBaseUrl"  ></app-vimeo-video>
        </div>

        <div *ngIf="courseDetails.overview_video_host != 'VIMEO'">
        <vm-player theme="light" >
            <vm-video cross-origin [poster]="courseDetails.course_banner | urlDecryption :assetBaseUrl">
                <source [src]="courseDetails.overview_video_link | urlDecryption: assetBaseUrl" type="video/mp4" />
            </vm-video>
            <vm-default-ui>
                <vm-default-controls></vm-default-controls>
            </vm-default-ui>
    </vm-player>
</div>

    </div>
</ng-template>

<!--end-->

<ng-template #assesmentModal let-modal>
    <div class="modal-header py-2">
        <h4 class="modal-title" id="modal-basic-title">{{lessonDetails['chapter_name']}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body justify-content-center bg-light">

        <div class="d-flex mx-5">
            <div class="p-2 flex-grow-1 bd-highlight">
                <h5 class="font-weight-bold">{{assesmentDetails['assesment_title']}}</h5>
            </div>
        </div>

        <div class="card mx-5 shadow-sm p-2 mb-3 bg-white rounded">
            <h5 class="font-weight-bold">Declaration of Independence Quiz</h5>
            <hr />
            <div class="row text-left">
                <div class="col-md-4">
                    <span class="question-font">Due</span> Aug 25
                </div>
                <div class="col-md-4">
                    <span class="question-font">Question</span> {{assesmentDetails['exam_assigned_list'].length}}
                </div>
                <div class="col-md-4">
                    <span class="question-font">Time Limit</span> {{assesmentDetails['assesment_time_in_min']}} Minutes
                </div>
            </div>
            <hr />

            <div class="card-body padding-l-unset">
                <h5 class="font-weight-bold">Instructions</h5>
                <p>{{assesmentDetails['instruction']}}
                </p>
            </div>
            <div class="text-center justify-content-center py-4">
                <button type="button" class="btn btn-danger bt-md"
                    (click)="takeAssesment(assesmentDetails,startAssesmentModal);">Take the Assessment</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #startAssesmentModal let-modal>
    <div class="modal-header py-2">
        <h4 class="modal-title" id="modal-basic-title">{{lessonDetails['chapter_name']}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="triggerConfirmationModal(confirmationModal)">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body justify-content-center bg-light">

        <div class="d-flex mx-5">
            <div class="p-2 flex-grow-1 bd-highlight">
                <h5 class="font-weight-bold">{{assesmentDetails['assesment_title']}}</h5>
            </div>
            <div class="p-2 bd-highlight">
                <h6>
                    <countdown #cd [config]="config" class="custom-style" (event)="handleEvent($event)"></countdown>
                </h6>
            </div>
        </div>

        <div class="card mx-5 shadow-sm p-2 mb-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 question-font">
                        {{assesmentQuestionIndex+1}}. &nbsp;
                        {{assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_title']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12"
                        *ngFor="let choice of assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['choice_list']; let choiceIndex=index">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" [id]="choiceIndex"
                                (click)="selectChoice(choice['choice_id'],choiceIndex,assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_bank_id'])"
                                [checked]="checkQuizAnswerMap(assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_bank_id'],choice['choice_id'])">
                            <label
                                [ngClass]="[choice['selected'] == true ? 'form-check-label choice-selected' : 'form-check-label']"
                                [for]="choiceIndex">
                                {{choice['choice_title']}}
                            </label>
                        </div>
                        <br>
                    </div>
                    <div class="col-md-12">
                        <div class="justify-content-center py-4">
                            <button type="button" class="btn btn-danger bt-md" (click)="goToPrevQuestion()"
                                *ngIf="assesmentQuestionIndex > 0">Prev</button>
                            <button type="button" class="btn btn-danger bt-md button-group-margin"
                                (click)="submitSingleAssesment(assesmentDetails['exam_assigned_list'][assesmentQuestionIndex],assesmentResultModal,assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_bank_id'])">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #assesmentResultModal let-modal>
    <div class="modal-header py-2">
        <h4 class="modal-title" id="modal-basic-title">{{lessonDetails['chapter_name']}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body justify-content-center bg-light">

        <div class="d-flex mx-5">
            <div class="p-2 flex-grow-1 bd-highlight">
                <h5 class="font-weight-bold">{{assesmentDetails['assesment_title']}}</h5>
            </div>
            <!-- <div class="p-2 bd-highlight">
                <h6>1:00:00 <span>min</span></h6>
                <p>Last of the Section</p>
            </div> -->
        </div>

        <div class="card mx-5 shadow-sm p-2 mb-3 bg-white rounded">
            <h5 class="font-weight-bold">Declaration of Independence Quiz</h5>
            <hr />
            <div class="row text-left">
                <div class="col-md-6">
                    <span class="question-font">Question Attempted:</span>
                    {{(assesmentResult['totalAns'])}}/{{assesmentResult['totalQus']}}
                </div>
                <div class="col-md-6">
                    <span class="question-font">Score:</span> {{assesmentResult['mark']}}%
                </div>
            </div>
            <hr />

            <div class="card-body text-center justify-content-center">
                <div class="row text-center" *ngIf="assesmentResult['is_pass'] == false">
                    <div class="col-md-12 assesment-result">
                        Sorry! That's Incorrect
                    </div>
                    <div class="col-md-12">
                        <p>It seems that you have selected too many incorrect answers. You need to get a score of at
                            least {{assesmentResult['passMark']}}% to clear the assesment.
                        </p>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <p>Click on the "Retake Assessment" button below to try again.
                        </p>
                    </div>
                    <div class="col-md-12">
                        <div class="text-center justify-content-center py-4">
                            <button type="button" class="btn btn-danger bt-md"
                                (click)="retakeAssesment(assesmentResultModal,assesmentModal)">Retake
                                Assessment</button>
                        </div>
                    </div>
                </div>

                <div class="row text-center" *ngIf="assesmentResult['is_pass'] == true">
                    <div class="col-md-12 assesment-result">
                        Congratulations!
                    </div>
                    <div
                        *ngIf="(assesmentResult['final_quiz'] != undefined && assesmentResult['final_quiz'] == true); else assesmentCompleted">
                        <div class="col-md-12 assesment-result">
                            <p>You have successfully completed the Eton College Agent Training Course.
                            </p>
                        </div>
                        <br>
                        <div class="col-md-12">
                            <p>Click on "Downlaod Certificate" button below to get your course completion certificate.
                            </p>
                        </div>
                        <div class="col-md-12">
                            <div class="text-center justify-content-center py-4">
                                <button type="button" class="btn btn-danger bt-md"
                                    (click)="downloadCertificate(assesmentResultModal)">Download Certifcate</button>
                            </div>
                        </div>
                    </div>
                    <ng-template #assesmentCompleted>
                        <div class="col-md-12 assesment-result">
                            <p>You have successfully cleared the assessment.
                            </p>
                        </div>
                        <br>
                        <div class="col-md-12">
                            <p>Click on the "Continue Course" button below to proceed.
                            </p>
                        </div>
                        <div class="col-md-12">
                            <div class="text-center justify-content-center py-4">
                                <button type="button" class="btn btn-danger bt-md"
                                    (click)="continueCourse(assesmentResultModal)">Continue Course</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #confirmationModal let-modal>

    <div class="modal-header py-2">

        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body justify-content-center bg-light">
        <p class="mx-3 py-3">Are you sure you want to exit the assessment? Clicking <b>Yes</b> will submit the
            assessment irrespective of its completion.</p>
        <br>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">No</button>
        <button type="button" class="btn btn-primary" (click)="quitAssesment(assesmentResultModal)">Yes</button>
    </div>
</ng-template>

<ng-template #confirmationLessonCompleteModal let-modal>

    <div class="modal-header py-2">

        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">

            <span aria-hidden="true">×</span>

        </button>

    </div>

    <div class="modal-body justify-content-center bg-light">
        <p class="mx-3 py-3">Are you sure you want to mark this lesson as complete ?</p>
        <br>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeLessonCompletePopup()">No</button>
        <button type="button" class="btn btn-primary" (click)="markLessonCompleteConfirm()">Yes</button>
    </div>
</ng-template>

<ng-template #modalDataForChapterHeaders let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{lessonDetails.chapter_name}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="lessonDetails.lesson_host != 'VIMEO'">
        <vm-player theme="light" >
            <vm-video cross-origin [poster]="lessonDetails.lesson_thumb_image | urlDecryption :assetBaseUrl ">
                <source [src]="lessonDetails.lesson_url | urlDecryption: assetBaseUrl" type="video/mp4" />
            </vm-video>
            <vm-default-ui>
                <vm-default-controls></vm-default-controls>
            </vm-default-ui>
        </vm-player>
    </div>
    <div class="modal-body" *ngIf="lessonDetails.lesson_host == 'VIMEO'">
        <app-vimeo-video [fileUrl]="lessonDetails.lesson_url" [assestBaseUrl]="assetBaseUrl"  ></app-vimeo-video>
    </div>
</ng-template>

<div>
    <ngx-scrolltop></ngx-scrolltop>