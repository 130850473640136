import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
 hide:boolean=false; 

  constructor() { }

  toggleSideNav(): void {
    this.hide = !this.hide;
  }
}
