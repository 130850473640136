import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EncryptionService } from 'app/services/encryption.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { MyProfileService } from 'app/services/my-profile.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-newnavbar',
  templateUrl: './newnavbar.component.html',
  styleUrls: ['./newnavbar.component.scss']
})
export class NewnavbarComponent implements OnInit {
@Output() sideToggle = new EventEmitter<boolean>();
menuStatus:boolean=false;
assetBaseUrl: string;
userDetails:any;
isLoading: boolean = false;
dataLoaded=false;
private onDestroy$: Subject<void> = new Subject<void>();


  
constructor(private myprofileservice: MyProfileService,private encryptionService: EncryptionService,private localStorageService: LocalStorageService) { 
}

ngOnDestroy(): void {
  this.onDestroy$.next();
  this.onDestroy$.complete();
}



  ngOnInit(): void {
    this.localStorageService.userDetails.subscribe((value) => {
        let userDetails = JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem("qa-userDetails")));
        if(userDetails){
          this.userDetails = userDetails.user_details;
          this.assetBaseUrl = userDetails.asset_base_url;
          this.dataLoaded = true;
        }else{
          this.userDetails = null;
          this.assetBaseUrl = null;
          this.dataLoaded = false;
        }
    });
    
  }
  sideNav()
  {
    this.menuStatus=!this.menuStatus;
    this.sideToggle.emit(this.menuStatus)
  }
  profile() {
    // this.isLoading = true;
    // this.myprofileservice.myProfile().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
    //   // console.log('myprofile.......',res)
    //   this.userDetails = res.user_details;
    //   this.assetBaseUrl = res.user_details.photo_base_url;
    //   this.isLoading = false;
    // }, error => {
    //   this.isLoading = false;
    // });
  }

}
