import { Pipe, PipeTransform } from '@angular/core';
import { EncryptionService } from 'app/services/encryption.service';

@Pipe({
  name: 'urlDecryption'
})
export class UrlDecryptionPipe implements PipeTransform {

  constructor(private encryptionService : EncryptionService){
  }

  transform(value: string,baseUrl:string): string {
    value = this.encryptionService.get(value);
    if(value != 'error')
    return baseUrl+value;
    return '';
  }
}
