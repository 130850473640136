
<div *ngIf="!(location == '/login') && !(location == '/lms-admin/login') && !(location == '/lms-admin/forgot-password') && !(location == '/lms-admin/reset-password')" >
    <div class="container-fluid g-0" *ngIf="checkUserType() == 'Agent'">
        <div class="d-flex">
        <div class="new-sidebar">
        <app-newsidebar [sideNavStatus]="sideNavStatus" [ngClass]="{'app-side-nav':sideNavStatus}"></app-newsidebar>
        </div>
        
        <div class="new-navbar">
           <app-newnavbar (sideToggle)="sideNavStatus = $event;"></app-newnavbar>
            <div class="main-body">
                <router-outlet></router-outlet>
            </div>
            <div class="contactus"> Contact Us : 
             <a href="mailto:learner@qacademy.ca"
                 target="_blank" >learner@qacademy.ca</a>
             </div>

        </div>
        </div>

        <app-footer></app-footer>
    </div>


    <div *ngIf="checkUserType()== 'Admin'">
        <router-outlet></router-outlet>
    </div>

</div>


<div *ngIf="(location == '/login') || (location == '/lms-admin/login') || (location == '/lms-admin/forgot-password')  || (location == '/lms-admin/dashboards') || (location == '/lms-admin/reset-password')">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div> 




