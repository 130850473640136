import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminApiHandleService } from 'app/services/admin-api-handle.service';
import { AdminEncryptionService } from 'app/services/admin-encryption.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import {ErrorStateMatcher} from '@angular/material/core';
import{CookieService} from 'ngx-cookie-service';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();
  email:any;
  password :any;
  defaultAuth: any;
  loginForm: any;
  fb: any;
  txt_email: any;
  txt_password: any;
  hasError: boolean;
  unsubscribe: any;
  remember: boolean;
  returnUrl: any = 'lms-admin/dashboards';
  loginerror: any;
  expiredMessage: any;
  formdata: any;
  isLoading: boolean = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  constructor(public router:Router,
    private apiHandleService :AdminApiHandleService,
    private route: ActivatedRoute,
    private builder: FormBuilder,
    private toastr: ToastrService,
    private adminEncryptionService: AdminEncryptionService,
    private cookieService : CookieService) {
     }

  ngOnInit(): void {
    this.apiHandleService.setIpArress();
    let usedetails = localStorage.getItem('S1');
    if (usedetails) {
      let userparse = JSON.parse(usedetails);
      this.email = this.adminEncryptionService.get1(this.adminEncryptionService.getUD(), userparse.A1);
      this.password = this.adminEncryptionService.get1(this.adminEncryptionService.getUD(), userparse.A2);
      this.remember = userparse.A3
    }
  }

  enterPhoneOrEmail(event){
    alert(event);
  }
  
  submit(){
     this.apiHandleService.setIpArress();
        this.isLoading = false;
        if(this.email!= "" && this.password!= ""){
          let obj={
            txt_email :this.email,
           txt_password : this.password,
        }
        this.isLoading = true
     this.apiHandleService.apipost(obj).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
       this.isLoading = false;
       this.toastr.success(res.message)
       localStorage.setItem("qa-userType","Admin")
      localStorage.setItem('msm-admin-auth-storage', this.adminEncryptionService.encryptUsingAES256(JSON.stringify(res.token_details)));
      this.router.navigateByUrl('lms-admin/dashboards'); 
     }, error => {
       this.isLoading = false;
       this.toastr.error('Email or Password is incorrect', 'Failed');
     });
     if (this.remember) {
       const encryptedUser = this.adminEncryptionService.set(this.adminEncryptionService.getUD(), this.email);
       const encryptedPass = this.adminEncryptionService.set(this.adminEncryptionService.getUD(), this.password);
       localStorage.setItem('S1', JSON.stringify({ A1: encryptedUser, A2: encryptedPass, A3: this.remember }));
     }
}
    }
    checkValue() {
      this.remember === true ? '' : localStorage.removeItem('S1');
    }
  

    isEmailValid = (val:string):boolean => /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(val);

}
