import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router'
import { EncryptionService } from './encryption.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    APIURL: string = environment.apiURL + 'api/';
    constructor(private http: HttpClient, private router: Router,private encryptionService:EncryptionService) { }

    sendOTP(phoneOrEmail) {
        return this.http.post<any>(this.APIURL + "sendOTP", { txt_email_phone: phoneOrEmail })
    }

    verifyOtp(phoneOrEmail, otp) {
        return this.http.post<any>(this.APIURL + "verifyOtp", { txt_email_phone: phoneOrEmail, txt_otp: otp })
    }

    userProfile() {
        return this.http.get<any>(this.APIURL + "profile")
    }

    refreshToken() {
        let token = localStorage.getItem('qa-academy-auth-storage') ? JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem('qa-academy-auth-storage'))).refresh_token:null;
       if (token == null){
        return
       }
        this.http.post<any>(this.APIURL + "refresh-token", {refresh_token: token}).subscribe(res => {
            localStorage.setItem('qa-academy-auth-storage', this.encryptionService.encryptUsingAES256(JSON.stringify(res.token_details)));
        }, error => {
            console.log("Logged out")
            localStorage.removeItem('qa-academy-auth-storage');
        });
    }

    
    refreshSourceToken(sourceToken) {
        return this.http.post<any>(this.APIURL + "refresh-token", {refresh_token: sourceToken});
    }

    logout(sessionExpired?: boolean) {
       return this.http.get<any>(this.APIURL + "logout")
    }

}
