import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'app/services/auth.service'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {


    constructor(private toastr: ToastrService, private authService: AuthService, private router: Router) {
    }

    ngOnInit(): void {
        if(localStorage.getItem('qa-academy-auth-storage')) {
            this.authService.logout().subscribe(res => {
                // this.toastr.success('Your have logged out!')
            }, error => {
                this.toastr.error('Something went wrong!')
            });
            localStorage.removeItem('qa-academy-auth-storage');
            localStorage.removeItem('qa-userDetails');
            localStorage.removeItem('qa-userType');
            this.router.navigate(['/login']);
        } else {
            this.router.navigate(['/login']);
        }
    }
}