<div class="login">
    <div class="login-navbar">
        <div class="container d-flex">
            <a routerLink="/"><img class="logo" src="./assets/images/EtonCollegeLogo.png" alt="logo" loading="lazy"></a>
            <h3 class="space-text font-subheading ">Eton College- Learning Management System</h3>
        </div>
    </div>
    <div class="container content">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6">
                <img class="logo" src="./assets/images/Login_screen.png" alt="login" loading="lazy">
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 log py-5"  >
                <div [ngClass]="isOTPSent ? 'd-none':'user-input text-center'">
                    <div class="mb-5">
                        <h3 class="font-subheading1">Enter your Phone number or Email</h3>
                        <p class="body-font">We will send OTP to your registered Phone number or Email</p>
                    </div>
                    <form>
                        <div class="form-group d-flex">
                            <select *ngIf="isPhoneNumber" [(ngModel)]="countryInput" [ngModelOptions]="{standalone: true}" class="form-select form-control" style="width: 100px;" placeholder="+ 91">
                                <option class="d-none" [value]="countryInput" selected>{{countryInput}}</option>
                                <option *ngFor="let item of country" value="{{item.dial_code}}">{{item.name}} <span>{{item.dial_code}}</span></option>
                              </select> &nbsp;&nbsp;
                            <input autofocus="true" required #phoneOrEmailInput type="text" name="phoneOrEmail" (input)="enterPhoneOrEmail($event)"
                                class="form-control" placeholder="Phone number or Email">

                        </div>
                        <!-- <div>
                            <p><b>Please Enter Email or Phone Number</b></p> 
                        </div> -->

                        <button (click)="sendOTP()" type="submit" [disabled]="isLoading">  <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Send OTP</button>
                    </form>
                </div>
                <div *ngIf="isOTPSent" class="user-input text-center">
                    <div class="mb-5">
                        <h3 class="font-subheading1 py-2">OTP Verification</h3>
                        <p class="body-font mb-1">Enter the OTP sent to your Phone number or Email </p>
                        <p> <span class="text_color">{{isPhoneNumber == true ? countryInput : ''}} {{phoneOrEmail}} </span> 
                        <i class="bx bx-edit-alt" style="cursor: pointer;" (click)="edit()"></i> </p>
                    </div>
                    <form>
                        <div class="form-group">
                            <ng-otp-input (onInputChange)="onOtpChange($event)"
                                [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
                        </div>
                        <button (click)="submitOTP()" type="submit"> <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submit</button>
                        <p class="mt-3 body-font">Didn't receive the OTP? <span *ngIf="!enableResend">Retry in </span>
                            <span class="resend body-font" *ngIf="enableResend" (click)="resendOTP()">Resend</span>
                            <countdown *ngIf="!enableResend" #cd
                                [config]="{format: 'mm:ss', leftTime: timeInSeconds, notify: timeInSeconds}"
                                (event)="timer($event)"></countdown>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>