import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/auth/login/login.component'
import { LogoutComponent } from './components/auth/logout/logout.component'
import { DashboardComponent } from './components/pages/dashboard/dashboard.component'
import { CoursesComponent } from './components/pages/courses/courses.component';
import { CourseDetailsComponent } from './components/pages/course-details/course-details.component';
import { PageNotFoundComponent } from './components/common/page-not-found/page-not-found.component'
import { CertificateComponent } from './components/pages/certificate/certificate.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { LessionInformationComponent } from './components/pages/lession-information/lession-information.component';
import { AssesmentComponent } from './components/pages/assesment/assesment.component';
import { QuizDetailsComponent } from './components/pages/quiz-details/quiz-details.component';

const routes: Routes = [
    {path: '', redirectTo: 'login',pathMatch:'full' },
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'courses',component: CoursesComponent, canActivate: [AuthGuard]},
    {path: 'course/:slug',component: CourseDetailsComponent, canActivate: [AuthGuard]},
    {path:'certificate', component:CertificateComponent, canActivate: [AuthGuard] },
    {path:'myProfile', component:MyProfileComponent, canActivate: [AuthGuard] },
    {path:'course/:slug/lesson/:lesson_name', component:LessionInformationComponent, canActivate: [AuthGuard] },
    {path:'course/:slug/assesment/:assesment_slug', component:AssesmentComponent, canActivate: [AuthGuard] }, 
    {path:'course/:slug/assesment/:assesment_slug/result/:assesment_test_id', component:QuizDetailsComponent,  canActivate: [AuthGuard] },
    {
        path: 'lms-admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {path: '**', component: PageNotFoundComponent},

    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }