import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DashboardService } from 'app/services/dashboard.service'
import { Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  data: any = {
    user_name: "Loading...",
    wish_string: "",
    date: ""
  };

  private onDestroy$: Subject<void> = new Subject<void>();

  @Output() menuOpenClose: EventEmitter<any> = new EventEmitter();

  @Input() menuOpen: boolean = true;

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  constructor(private dashboardService: DashboardService,private router: Router) {
  }

  menuToggle() {
    this.menuOpenClose.emit(!this.menuOpen)
  }

  ngOnInit(): void {
    if (localStorage.getItem('qa-academy-auth-storage')) {
      this.dashboardService.myDashbaord().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.data = res.data
      });
    }
  }

  logout(){
    localStorage.clear();
  }
}
