import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DashboardService } from 'app/services/dashboard.service'
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  data: any = {
    user_name: "Loading...",
    wish_string: "",
    date: ""
  };

  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit(): void {

  }
}
