import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  APIURL: string = environment.apiURL + 'api/';
  constructor(private http: HttpClient, private router: Router) { }
  myProfile() {
    return this.http.get<any>(this.APIURL + "profile")
}
}
