import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vimeo-video',
  templateUrl: './vimeo-video.component.html',
  styleUrls: ['./vimeo-video.component.scss']
})
export class VimeoVideoComponent implements OnInit {

  @Input() fileUrl:string;
  @Input() assestBaseUrl:string = '';


  constructor() { }

  ngOnInit(): void {
  }

}
