import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  constructor() { }

  @HostBinding('class.scroll_Sticky') scrollStick: boolean;

  @HostListener('window:scroll') onScroll() {
    console.log(window.scrollY);
    if (window.scrollY >= 50) {
      this.scrollStick = true;
    }
    else {
      this.scrollStick = false;
    }
  }


}
