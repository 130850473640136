
import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { CourseService } from 'app/services/course.services';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { EncryptionService } from 'app/services/encryption.service';
import { PreviousRouteServiceService } from 'app/services/previous-route-service.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-lession-information',
  templateUrl: './lession-information.component.html',
  styleUrls: ['./lession-information.component.scss']
})
export class LessionInformationComponent implements OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  @ViewChild('modalDataForChapterHeaders') modalDataForChapterHeaders;
  public slug:any;
  public agents:any;

  public courseDetails: any = {course_title: ""};
  public assetBaseUrl: string;
  public isLoading: boolean = false;
  public courseEnabled: boolean = true;
  public parent_hide:boolean = false;
  public courseErrMsg: string;
  public lessonDetails:any;
  public lesson_data:any;
  closeModal:any;
  completedLessonId = null;
  prerious:any;
  next:any;
  isError:boolean=false;
  isErrorStatus:any;
  previous_lesson:any;
  next_lessson:any;
  showPrevButton = true;
  showNextButton = true;
  public audioBaseUrl: string;
  scrollfixed:boolean =false;



  ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    size: 'xl',
    ariaLabelledBy: 'modal-basic-title'
};
  coursePpt: any;
  audio: HTMLAudioElement;
  chapterBaseUrl: any;
  chapterPptUrl: any;
  lessonPptUrl: any;
  lessonBaseUrl: any;
  lessonPpt: string;
  lesson_ppt_flag: boolean;
  chapter_ppt_flag: boolean;
@HostListener('window:scroll',['$event']) onscroll(){
  if(window.scrollY > 100){
    this.scrollfixed = true;
  }
  else{
    this.scrollfixed = false;
  }
}
  constructor(private courseService: CourseService, private route: ActivatedRoute,
     private encryptionservice:EncryptionService,private router:Router,
     private modalService: NgbModal,private toasService : ToastrService,
     private customRouteService: PreviousRouteServiceService, private location: Location ) { }

  ngOnInit(): void {
    this.route.params.subscribe((param : Params) => {
      this.slug = param['slug'];
      this.agents = param['lesson_name'];
      this.isLoading = true;
      this.getCourseDetails(param['slug']); 
    });
   
  }

  lessionDetails(){
    let apibody = {
      course_slug:this.slug,
      is_assessment:false,
      lesson_or_assessment_slug:this.agents
  }
    this.isLoading = true;
    this.courseService.getLessionDetails(apibody).subscribe((res)=>{
      this.lesson_data = res['data'];
      this.chapterPptUrl= res['data'].chapter_details.chapter_ppt_url
      this.lessonPptUrl= res['data'].lesson_details.lesson_ppt_url

      this.next = res['data'].next_lesson;
      this.prerious=res['data'].previous_lesson;
      this.assetBaseUrl = this.encryptionservice.get(res['course_assets_base_url']);
      this.audioBaseUrl  = this.encryptionservice.get(res['self_audio_host_base_url']);
      this.chapterBaseUrl = this.encryptionservice.get(this.chapterPptUrl);
      this.lessonBaseUrl = this.encryptionservice.get(this.lessonPptUrl);
      // console.log('chapterppt', this.chapterBaseUrl);
      // console.log('leesonppt', this.lessonBaseUrl);

      this.isLoading = false;
      this.setPrevNextButton();
      window.scroll(0,0);

      if((this.lessonPptUrl != undefined || this.lessonPptUrl!= null) && (this.chapterPptUrl == null || this.chapterPptUrl == undefined )){
        this.lesson_ppt_flag=true;
      }
      if((this.lessonPptUrl == undefined || this.lessonPptUrl == null) && (this.chapterPptUrl != undefined || this.chapterPptUrl != null)){
        this.chapter_ppt_flag=true;
      }
      if((this.lessonPptUrl == undefined || this.lessonPptUrl == null) && (this.chapterPptUrl == null || this.chapterPptUrl == undefined)){
        this.lesson_ppt_flag=false;
        this.chapter_ppt_flag=false;

      }
    },error =>{
      this.isLoading = false;
      if(error['status'] == 400){
        this.isError = true;
        // this.toasService.error(error.error['message'])
        this.isErrorStatus = error.error['message'];
      }else
      this.toasService.error("Some error occured.")
      
    })
  }

  setPrevNextButton(){
      if(this.lesson_data.previous_lesson != undefined && this.lesson_data.next_lesson != undefined){
        this.showPrevButton = Object.keys(this.lesson_data.previous_lesson).length == 0 ? false : true;
        this.showNextButton = Object.keys(this.lesson_data.next_lesson).length == 0 ? false : true;
      }
  }
  getCourseDetails(slug) {
    this.isLoading = true;
    this.courseService.getCourseDetails(slug).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.assetBaseUrl = this.encryptionservice.get(res.course_assets_base_url);
      this.courseDetails = res.data;
      this.lessonDetails = res.data.chapters;
      this.lessionDetails();
      this.courseEnabled = true;
    }, error => {
      this.isLoading = false;
      this.courseErrMsg = error.error.message ? error.error.message:'Contact Support Team';
      this.courseEnabled = false;
    });
  }


  triggerModal(content) {
    this.modalService.open(content, this.ngbModalOptions).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  playIntroVideo(modalDataForChapterHeaders){
    this.courseDetails.overview_video_host='Self';
    if(this.courseDetails.overview_video_link != undefined && this.courseDetails.overview_video_link != null){
       this.triggerModal(modalDataForChapterHeaders);
    }
  }

  closeLessonCompletePopup(){
    this.completedLessonId = null;
    this.modalService.dismissAll();
    this.refreshPageDetails();
  }

  
  markLessonAsComplete(lessonId,modalRef){
    this.completedLessonId = lessonId;
    this.triggerConfirmationModal(modalRef);
  }

  markLessonCompleteConfirm(){

    if(this.lesson_data['course_details']['course_id'] != null && this.lesson_data['lesson_details'].lesson_id !=null){
      this.isLoading = true;
      this.modalService.dismissAll();
      this.courseService.markLessonAsComplete(this.lesson_data['course_details']['course_id'],this.lesson_data['lesson_details'].lesson_id).subscribe(response =>{
        if(response){
          this.toasService.success("Lesson marked as completed");
          this.refreshPageDetails();
          this.isLoading = false;
        }
      },
      error => {
        this.modalService.dismissAll();
        this.handleError(error);
        this.isLoading = false;
        this.refreshPageDetails();
      }
      );
    }else{
      this.modalService.dismissAll();
      this.refreshPageDetails();
    }
  }

  refreshPageDetails() {
  this.lessionDetails();
  }


  triggerConfirmationModal(content) {
    this.modalService.open(content, {size: 'xs',ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  handleError(error){
    this.isLoading = false;
    if(error['error'] != undefined && error['error']['message'] != undefined){
       this.toasService.error("Error Occured - " + error['error']['message']);
    }
  }
   previousLesson()
   {

    if(this.prerious['is_assesment'] != undefined && this.prerious['is_assesment'] == true){
      if(this.prerious['is_already_completed'] != undefined && this.prerious['is_already_completed'] == true)
      this.router.navigate(['course/'+this.slug + '/assesment/' +this.prerious['assesment_details']['assesment_slug']+'/result/'+this.prerious['assesment_details']['assessment_test_id']])
      else{
        if(this.prerious['assesment_details']['is_failed'])
          this.router.navigate(['course/'+this.slug + '/assesment/' +this.prerious['assesment_details']['assesment_slug']+'/result/'+this.prerious['assesment_details']['assessment_test_id']])
          else
          this.router.navigate(['course/'+this.slug + '/assesment/' +this.prerious['assesment_details']['assesment_slug']]);
      }
    }
    else{
      if(this.prerious.lesson_slug !=undefined)
      this.router.navigate(['course/'+this.slug + '/lesson/' +this.prerious.lesson_slug]);
    }
   }
   nextLesson()
   {
    if(this.next['is_assesment'] != undefined && this.next['is_assesment'] == true){
      if(this.next['is_already_completed'] != undefined && this.next['is_already_completed'] == true)
      this.router.navigate(['course/'+this.slug + '/assesment/' +this.next['assesment_details']['assesment_slug']+'/result/'+this.next['assesment_details']['assessment_test_id']])
      else{
        if(this.next['assesment_details']['is_failed'])
          this.router.navigate(['course/'+this.slug + '/assesment/' +this.next['assesment_details']['assesment_slug']+'/result/'+this.next['assesment_details']['assessment_test_id']])
          else
          this.router.navigate(['course/'+this.slug + '/assesment/' +this.next['assesment_details']['assesment_slug']]);
      }
    }
    if(this.next['is_assesment'] != undefined && this.next['is_assesment'] == false){
      this.router.navigate(['course/'+this.slug + '/lesson/' +this.next.lesson_slug]);
    }
   }

  //  playAudio(){
  //   this.audio = new Audio();
  //       this.audio.src = "../../../../assets/images/Demoaudio.mp3";
  //        this.audio.load();
  //        this.audio.play();

  //        setTimeout(() => {
  //         this.audio.pause()
  //       }, 60000);
  //        }

  //  triggerQuizSlideModal(content) {
  //   this.modalService.open(content, {size: 'l',ariaLabelledBy: 'modal-basic-title',backdrop  : 'static',
  //   keyboard  : false }).result.then((res) => {
  //     this.closeModal = `Closed with: ${res}`;
  //   }, (res) => {
  //     this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
  //     this.audio.play();
  //     setTimeout(() => {
  //       this.audio.pause();
        
  //     }, 60000);
  //   }
  //   )
  // }

   triggerSlideModal(content) {
    this.modalService.open(content, {size: 'xl',ariaLabelledBy: 'modal-basic-title',backdrop  : 'static',
    keyboard  : false }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
      
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      // this.audio.pause();
      
    });
    
  }

   viewSlide(slidesModal){
    {
        this.coursePpt=  "https://view.officeapps.live.com/op/embed.aspx?src=" + this.assetBaseUrl + this.chapterBaseUrl      
      if(this.chapterBaseUrl!= undefined && this.chapterBaseUrl != null){
        this.triggerSlideModal(slidesModal);
        // console.log('chapterpptslde',this.coursePpt);      
        // this.playAudio();
        // setTimeout(() => {
          // alert('quizmodal')
        //   this.triggerQuizSlideModal(quizSlideModal)
        // }, 60000);        

      }
  
    }
  }
  viewLessonSlide(lessonSlidesModal){
    {
        this.lessonPpt=  "https://view.officeapps.live.com/op/embed.aspx?src=" + this.assetBaseUrl + this.lessonBaseUrl      
      if(this.chapterBaseUrl!= undefined && this.lessonBaseUrl != null){
        this.triggerSlideModal(lessonSlidesModal);   
        // console.log('lessonpptslde',this.lessonPpt);
      }
  
    }
  }
   goBack()
   {
    // this.router.navigate(['/course/'+this.slug])
    this.location.back()
    this.isError=false;


   }

   addHiglightClass(id){
      document.getElementById(id).classList.add("lms-highlight");
   }

   removeHiglightClass(id){
    document.getElementById(id).classList.remove("lms-highlight");
   }
   scrolltop()
   {
    window.scroll(0,0);
   }
}
