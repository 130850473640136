import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minuteSeconds'
  })
  export class MinuteSecondsPipe implements PipeTransform {

      transform(value: any): string {
        try{
            let finalString = '';
            value = value+'';
            var minutes = Math.floor((Number(value)*60) / 60);
            var seconds = Math.floor((Number(value)*60) - minutes * 60);
            if(minutes > 0){
               if(minutes == 1)
                   finalString = finalString + minutes + ' Minute ';
               else
                   finalString = finalString + minutes + ' Minutes ';
            }
            if(seconds > 0){
               if(seconds == 1)
                   finalString = finalString + seconds + ' Second';
               else
                   finalString = finalString + seconds + ' Seconds';
            }
            return finalString;
         }catch(err){
            return value + 'Minutes';
        }
    }
  }