<div style="height: 100%">
    <div class="side-bar  desktop-width" style="padding-right: 0;height: 100%">
        <div class="msm-logo">
            <a routerLink="/"><img src="./assets/images/EtonCollegeLogo.png"></a>
        </div>
        <div class="menu">
            <div routerLinkActive="active">
                <a routerLink="/dashboard" routerLinkActive="active">
                    <h5>Dashboard</h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/courses" routerLinkActive="active">
                    <h5>My Courses</h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/certificate" routerLinkActive="active">
                    <h5>My Certificates</h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/myProfile" routerLinkActive="active">
                    <h5>My Profile</h5>
                </a>
            </div>
            <div routerLinkActive="active">
                <a routerLink="/logout" routerLinkActive="active">
                    <h5>Logout</h5>
                </a>
            </div>
        </div>
       <!-- <div class="contactus"> ContactUs :<br>
       <p class="emialus">  <a href="mailto:support@msmunify.com"
        target="_blank" >support@msmunify.com</a></p>
    </div> -->
    <!-- <div class="side-bar  mobile-width" style="padding-right: 0;">
    
    </div> -->
</div>
</div>