<div *ngIf="resultDetailsPreview?.length == 0">
  <h5 class="error_text text-center py-2">You haven't answered any questions</h5>
</div>
<ng-container *ngIf="resultDetailsPreview?.length > 0">
<div class="card py-3"  *ngFor="let item of resultDetailsPreview; let i = index;"> 
  <div class="card-header py-2">
      <div class="row">
        <div class="col-md-11 col-11 col-lg-11">
          <div class="d-flex flex-row">
            <div class="p-2 question-tile">{{i+1}} .</div>
            <div class="p-2 question-tile" [innerHtml]="item.question_title"></div>
          </div>
          <!-- <h6 class="question-tile"> <span>{{i+1}} </span> &nbsp; <span [innerHtml]="item.question_title"></span></h6>  -->
        </div>
        <div class="col-md-1 col-1 col-lg-1 icon">

          <div *ngIf="item.test_status == 1">
            <i class="fa fa-check-circle fa-2x text-success" aria-hidden="true"></i>
          </div>

          <div *ngIf="item.test_status == 0" >
            <i class="fa fa-times-circle fa-2x text-danger" aria-hidden="true"></i>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body" *ngFor="let choice of item.choice_list; let i = index;">
       <input class="form-check-input"
       type="checkbox" value=""
       [id]="choice.choice_id" [checked]="choice.is_selected == 1 ? true : false"
       [disabled]="true">
      <label class="choice_title"> &nbsp; {{choice?.choice_title}}</label>
    </div>
  </div>
</ng-container>

  <ngx-scrolltop></ngx-scrolltop>