import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsidebar',
  templateUrl: './newsidebar.component.html',
  styleUrls: ['./newsidebar.component.scss']
})
export class NewsidebarComponent implements OnInit {
@Input() sideNavStatus:boolean= false;
  constructor() { }

  ngOnInit(): void {
  }

}
