<div class="">
  <div class="" style="margin-bottom: 40px">
    <h2 class="heaading-fontsize"><b>My Certificates</b></h2>
  </div>
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div>
    <table *ngIf="!isLoading && certificateDetails.length > 0" class="table ">
      <thead>
        <tr>
          <th scope="col">SL</th>
          <th scope="col">Date</th>
          <th scope="col">Course</th>
          <th scope="col">Certificate No</th>
          <th style="text-align: center;" scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let items of certificateDetails ; index as i">
          <td scope="row">{{ i + 1 }}</td>
          <td>{{ items.certificate_generated_at }}</td>
          <td>{{ items.course_name }}</td>
          <td>{{ items.certificate_id }}</td>
          <td style="text-align: center;"><button class="default-btn download-btn" (click)="downloadFile(items)">Download</button> &nbsp;
            <!-- <button class="default-btn view-button" (click)="view(items)">View</button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center">
    <h3 class="font-subheading" *ngIf="!isLoading && certificateDetails.length == 0">
      No Certificates Found!
    </h3>
  </div>
</div>
<ngx-scrolltop></ngx-scrolltop>