import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { LayoutService } from './services/layout.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    sideNavStatus=false;
    location: any;
    routerSubscription: any;
    menuOpen: boolean = true;

    constructor(private router: Router,public slide:LayoutService) {
    }

    ngOnInit(){
        this.recallJsFuntions();
        // console.log("prod")
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            if (!(event.id === 1 && event.url === event.urlAfterRedirects)) {
                localStorage.removeItem("isCurriculumSelected");
            }
            window.scrollTo(0, 0);
        });
    }

    menuToggle(event) {
        this.menuOpen = event;
    }

    checkUserType(){
        return localStorage.getItem('qa-userType')
    }
}