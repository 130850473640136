import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import * as CryptoJS from 'crypto-js';
import { environment } from "environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AdminEncryptionService {

  constructor() { }
  keySize = 256;
  salt = CryptoJS.lib.WordArray.random(16);
  iv = CryptoJS.lib.WordArray.random(128 / 8);

  tokenFromUI: string = "msm_lms_ls_token";
  encrypted: any = "";
  decrypted: string;

    //The get method is use for decrypt the value.
    get(encryptedBase64){
        try{
          var encrypted = Buffer.from(encryptedBase64, 'base64').toString('binary');
          encrypted = JSON.parse(encrypted);
          const value1 = encrypted['value'];
          const key1 = CryptoJS.enc.Base64.parse(this.getKey());
          const iv = CryptoJS.enc.Base64.parse(encrypted['iv']);
          const decrypted = CryptoJS.AES.decrypt(value1, key1, { iv: iv });
          const str = decrypted.toString(CryptoJS.enc.Utf8);
          return str;
        }catch(err){
            return 'error';
        }
    }
    set(keys, value){
      var key = CryptoJS.enc.Utf8.parse(keys);
      var iv = CryptoJS.enc.Utf8.parse(keys);
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  
      return encrypted.toString();
    }
    get1(keys, value){
      var key = CryptoJS.enc.Utf8.parse(keys);
      var iv = CryptoJS.enc.Utf8.parse(keys);
      var decrypted = CryptoJS.AES.decrypt(value, key, {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
    getUD()
    {
      return environment.rtfr1.substring(94,110);
    }

    getKey(){
      return environment.rtfr.toString();
    }

    encryptUsingAES256(text) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(text), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
      return encrypted.toString();
    }
    decryptUsingAES256(encText) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  
      let decrypted = CryptoJS.AES.decrypt(
        encText, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted); 
    }
}
