<div class="profile-area  pb-70">
    <div class="container-fluid">
        <app-spinner *ngIf="isLoading"></app-spinner>
        <div *ngIf="!isLoading">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                    <img [src]="assetBaseUrl+userDetails?.photo" alt="image">
                </div>
                <div class="col-lg-10 col-md-10 col-sm-12 col-12 profile-box">
                    <div class="content">
                        <h2 class="font-weight-bold heaading-fontsize">My Profile</h2>
                        <br />
                        <ul class="info">
                            <li><span>Registered On </span>
                                <a style="margin-left: 75px" class="register"> <span> :</span>
                                    &nbsp;{{userDetails?.registered_on | date:'mediumDate'}}
                                </a>
                            </li>

                            <li>
                                <span>First Name</span>
                                <a class="text_content"> <span> :</span>
                                    &nbsp;{{userDetails.first_name == undefined || userDetails.first_name=='' ||
                                    userDetails.first_name==null ? 'NA' : userDetails.first_name}}
                                </a>
                            </li>
                            <li>
                                <span>Last Name</span>
                                <a class="text_content"> <span> :</span>
                                    &nbsp;{{userDetails.last_name == undefined || userDetails.last_name=='' ||
                                    userDetails.last_name==null ? 'NA' : userDetails.last_name}}
                                </a>
                            </li>

                            <li><span>Email Address </span>

                                <a *ngIf="userDetails.email == undefined || userDetails.email=='' || userDetails.email==null"
                                    style="margin-left: 80px"> <span> :</span>
                                    &nbsp; <span> NA</span>
                                </a>

                                <a *ngIf="userDetails.email != undefined && userDetails.email!='' && userDetails.email!=null"
                                    style="margin-left: 80px" class="email" href="mailto:{{userDetails.email}}"
                                    target="_blank"> <span> :</span>
                                    &nbsp; <span class="email"> {{userDetails?.email}}</span>
                                </a>

                            </li>

                            <li>
                                <span>Phone Number </span>

                                <a *ngIf="userDetails.phone == undefined || userDetails.phone=='' || userDetails.phone==null"
                                    style="margin-left: 75px"><span> :</span>
                                    &nbsp; NA 
                                </a>

                                <a *ngIf="userDetails.phone != undefined && userDetails.phone !='' &&  userDetails.phone !=null"
                                    style="margin-left: 75px" class="phoneNumber"
                                    href="tel:{{userDetails?.phone}}"><span> :</span>
                                    &nbsp; <span class="phoneNumber"> {{userDetails?.phone}}</span>
                                </a>

                            </li>
                            <li><span>Country </span>
                                <a style="margin-left: 132px" class="country"> <span> :</span>
                                    &nbsp; {{userDetails.country == undefined || userDetails.country=='' ||
                                    userDetails.country==null ? 'NA' : userDetails.country}}
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

        </div>
        <ngx-scrolltop></ngx-scrolltop>