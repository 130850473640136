import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component'
import { DashboardComponent } from './components/pages/dashboard/dashboard.component'
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './interceptor/jwt.Interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { LogoutComponent } from './components/auth/logout/logout.component'
import { FooterComponent } from './components/common/footer/footer.component'
import { ToastrModule } from 'ngx-toastr';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CoursesComponent } from './components/pages/courses/courses.component'
import { PageNotFoundComponent } from './components/common/page-not-found/page-not-found.component'
import { CourseDetailsComponent } from './components/pages/course-details/course-details.component';
import { SpinnerComponent } from './components/common/spinner/spinner.component';
import { VimeModule } from '@vime/angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FileSaverModule } from 'ngx-filesaver';
import { CertificateComponent } from './components/pages/certificate/certificate.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { UrlDecryptionPipe } from './pipes/url-decryption.pipe';
import { LessionInformationComponent } from './components/pages/lession-information/lession-information.component';
import { AssesmentComponent } from './components/pages/assesment/assesment.component';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CounterPipe } from './pipes/counter.pipe';
import { VideoEncryptPipe } from './pipes/video-encrypt.pipe';
import { SlicingPipe } from './pipes/slicing.pipe';
import { NewsidebarComponent } from './components/common/newsidebar/newsidebar.component';
import { NewnavbarComponent } from './components/common/newnavbar/newnavbar.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import { NgApexchartsModule } from 'ng-apexcharts';
import { QuizDetailsComponent } from './components/pages/quiz-details/quiz-details.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialsModule } from './admin/core/materials/materials.module';
import { QuizResultComponent } from './components/pages/quiz-result/quiz-result.component';
import { VimeoVideoComponent } from './components/pages/vimeo-video/vimeo-video.component';
import { ScrollDirective } from './directives/scroll.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';



@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    CoursesComponent,
    LoginComponent,
    LogoutComponent,
    DashboardComponent,
    PageNotFoundComponent,
    FooterComponent,
    CourseDetailsComponent,
    SpinnerComponent,
    AppComponent,
    CertificateComponent,
    MyProfileComponent,
    UrlDecryptionPipe,
    MinuteSecondsPipe,
    LessionInformationComponent,
    AssesmentComponent,
    CounterPipe,
    VideoEncryptPipe,
    SlicingPipe,
    NewsidebarComponent,
    NewnavbarComponent,
    AdminLoginComponent,
    QuizDetailsComponent,
    QuizResultComponent,
    VimeoVideoComponent,
    ScrollDirective,
    SafeHtmlPipe,
    
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgOtpInputModule,
    CountdownModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    VimeModule,
    NgbModule,
    FileSaverModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatSidenavModule,
    MatToolbarModule,
    BrowserModule,
    MatListModule,
    NgApexchartsModule,
    NgApexchartsModule,
    MatFormFieldModule,
    MaterialsModule,
    MatGridListModule
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }