<section class="container-fluid" *ngIf="!isLoading && (lesson_data?.lesson_details?.is_already_completed==false)">
  <div class="page-title-content   py-3" *ngIf="!isCourseDone">
    <ul>
      <li><a class="font-change" id ="dashboard" (mouseover)="addHiglightClass('dashboard')" (mouseout)="removeHiglightClass('dashboard')" routerLink="/dashboard">Dashboard</a></li>
      <li><a class="font-change" id ="mycourses" (mouseover)="addHiglightClass('mycourses')" (mouseout)="removeHiglightClass('mycourses')" routerLink="/courses">My Courses</a></li>
      <li> <a class="font-change" id ="course" (mouseover)="addHiglightClass('course')" (mouseout)="removeHiglightClass('course')" routerLink="/course/{{courseDetails.course_slug}}">{{courseDetails.course_title}}
          Coursess</a></li>
      <li> <a class="font-change" id ="assessment" (mouseover)="addHiglightClass('assessment')" (mouseout)="removeHiglightClass('assessment')">{{assesmentDetails?.assesment_title}}</a></li>
    </ul>
  </div>
  <br>
  <div class="" *ngIf="assesmentDeclarationFlag">

    <div class=" mb-5 space-allignment">

      <div class="d-flex" style="justify-content: space-between;">
        <div>
      <div class="body-font" style="color:  #105F96; margin-bottom: 5px;">Lesson {{lesson_data?.lesson_details?.current_lesson}} of
        {{lesson_data?.lesson_details?.total_lessons}}</div>
          <h5 class="font-weight-bold font-subheading">{{assesmentDetails?.assesment_title}}</h5>
        </div>
        <hr />

        <div>
          <button class="btn btn-danger lms-button text-bold"
          routerLink="/course/{{courseDetails.course_slug}}">Back to Course</button>
        </div>
      </div>
<hr/>
      <div class="row ">
        <div class="col-md-6 text-font-color">
          <span class="question-font font-weight-bold body-font">Total Questions:</span> {{assesmentDetails?.exam_assigned_list.length}}
        </div>
        <div class="col-md-6 body-font moblie-position">
          <span class="question-font font-weight-bold body-font ">Time Limit:</span> {{assesmentDetails?.assesment_time_in_min | minuteSeconds}} 
        </div>
      </div>
      <hr />

      <div class="padding-l-unset">
        <h5 class="font-weight-bold ">Instructions</h5>
        <p class="body-font">{{assesmentDetails['instruction']}}
        </p>
      </div>
      <div class="py-4 mobile-allignment" *ngIf="!alreadyFailed">
        <button type="button" class="btn btn-danger bt-md" (click)="takeAssesment(assesmentDetails)">Take the
          Assessment</button>
      </div>
      <div class="row text-font-color"  *ngIf="alreadyFailed">
        <div class="col-md-12 mobile-allignment">
          <div class="py-4">
            <button type="button" class="btn btn-danger bt-md" (click)="takeAssesment(assesmentDetails)">Retake
              Assessment</button>
            <button type="button" class="btn btn-danger bt-md questions mx-2" (click)="quizResultDisplay()">View Questions </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="" *ngIf="assesmentProgressFlag">
    <div class="modal-header py-2">
      <!-- <h4 class="modal-title" id="modal-basic-title">{{lessonDetails['chapter_name']}}</h4> -->
      <!-- <button type="button" class="close" aria-label="Close" (click)="triggerConfirmationModal(confirmationModal)">
            <span aria-hidden="true">×</span>
        </button> -->
    </div>
    <div class="justify-content-center space-allignment">
      <div class="d-flex">
        <div class=" flex-grow-1 bd-highlight">
          <div class="body-font" style="color:  #105F96; margin-bottom: 5px;">Lesson {{lesson_data?.lesson_details?.current_lesson}} of
            {{lesson_data?.lesson_details?.total_lessons}}</div>
          <h5 class="font-weight-bold font-subheading">{{assesmentDetails['assesment_title']}}</h5>
        </div>
        <div class="p-2 bd-highlight">
          <h6>
           <span class="font-weight-bold body-font text-font-color">Time Left:</span> &nbsp;<countdown #cd [config]="config" class="custom-style" (event)="handleEvent($event)"></countdown>
          </h6>
        </div>
      </div>

      <div class=" mb-3" *ngIf="!choiceSubmitted">
        <div>
          <div class="d-flex flex-row">
            <div class="p-2">{{assesmentQuestionIndex+1}}.</div>
            <div class="p-2" [innerHtml]="assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_title']"></div>
          </div>
          <br>
          <div class="row text-font-color">
            <div class="col-md-12"
              *ngFor="let choice of assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['choice_list']; let choiceIndex=index">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" [id]="choiceIndex"
                  (click)="selectChoice(choice['choice_id'],choiceIndex,assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_bank_id'])"
                  [checked]="checkQuizAnswerMap(assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_bank_id'],choice['choice_id'])">
                <label
                  [ngClass]="[choice['selected'] == true ? 'form-check-label choice-selected' : 'form-check-label']"
                  [for]="choiceIndex">
                  &nbsp;{{choice['choice_title']}}
                </label>
              </div>
              <br>
            </div>
            <div class="col-md-12">
              <div class="justify-content-center py-2">
                <button type="button" class="btn btn-danger bt-md mx-3" (click)="goToPrevQuestion(); scrollTop()"
                  *ngIf="assesmentQuestionIndex > 0">Prev</button>
                <button type="button" class="btn btn-danger bt-md button-group-margin"
                  (click)="submitSingleAssesment(assesmentDetails['exam_assigned_list'][assesmentQuestionIndex],assesmentDetails['exam_assigned_list'][assesmentQuestionIndex]['question_bank_id']); scrollTop()">Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-spinner *ngIf="choiceSubmitted"></app-spinner>
    </div>
  </div>

  <div class="" *ngIf="assesmentResultFlag" >
    <div class="modal-header py-2">
      <!-- <h4 class="modal-title" id="modal-basic-title">{{lessonDetails['chapter_name']}}</h4> -->
      <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button> -->
    </div>
    <div class="modal-body justify-content-center space-allignment">
      <div class="">
        <div class="body-font" style="color:  #105F96;">Lesson {{lesson_data?.lesson_details?.current_lesson}} of
          {{lesson_data?.lesson_details?.total_lessons}}</div>
          <div class="d-flex" style="justify-content: space-between;">
        <div>  <h2 class="font-weight-bold font-subheading">{{assesmentDetails['assesment_title']}}</h2></div>
          <div>
            <button class="btn btn-danger lms-button text-bold"
            routerLink="/course/{{courseDetails.course_slug}}">Back to Course</button>
          </div></div>


        <!-- <div class="p-2 bd-highlight">
                <h6>1:00:00 <span>min</span></h6>
                <p>Last of the Section</p>
            </div> -->
      </div>

      <div class="mb-3">
        <h6 class="font-weight-bold body-font text-font-color">Submission Details:</h6>
        <hr />
        <div class="row text-center">
          <div class="col-md-4 left-align body-font ">
            <span class="question-font font-weight-bold body-font text-font-color">Correct Answers:</span>
            {{(assesmentResult['totalCorrect'])}}/{{assesmentResult['totalQus']}}
          </div>
          <div class="col-md-4 body-font text-font-color moblie-position">
            <span class="question-font font-weight-bold">Time:</span>
             {{assesmentResult?.total_time_taken_in_min | minuteSeconds }}
             <!-- {{assesmentResult['total_time_taken_in_min']}}  -->
          </div>
          <div class="col-md-4 body-font text-font-color moblie-position">
            <span class="question-font font-weight-bold text-font-color">Score:</span> {{assesmentResult?.mark}}%
          </div>
        </div>
        <hr />

        <div class="">
          <div class="row" *ngIf="assesmentResult['is_pass'] == false">
           <div *ngIf="assesmentResult.totalWrong!=0">
            <div class="col-md-12 assesment-result text-font-color">
              <h2 class="font-weight-bold font-subheading">Sorry! That's Incorrect</h2> 
             </div>
             <div class="col-md-12 body-font text-font-color">
               <p>It seems that you have selected too many incorrect answers. You need to get a score of at
                 least {{assesmentResult['passMark']}}% to clear the assessment.
               </p>
             </div>
             <br>
             <div class="col-md-12 body-font text-font-color">
               <p>Click on the "Retake Assessment" button below to try again.
               </p>
             </div>
           </div>

           <div *ngIf="assesmentResult.totalWrong==0">
            <div class="col-md-12 assesment-result text-font-color">
              <h2 class="font-weight-bold font-subheading">Sorry! You ran out of time.</h2> 
             </div>
             <div class="col-md-12 body-font text-font-color">
               <p>You have exceeded the time limit allotted for this test. Only questions answered within the time limit will be considered for calculating final marks. Alternatively, you may choose to reattempt the test by clicking on "Retake Assessment" button below.
               </p>
             </div>
             <br>
             <div class="col-md-12 body-font text-font-color">
               <p>
               </p>
             </div>
           </div>
            <div class="col-md-12 mobile-allignment">
              <div class="py-4">
                <button type="button" class="btn btn-danger bt-md" (click)="retakeAssesment()">Retake
                  Assessment</button>

                <button type="button" class="btn btn-danger bt-md questions mx-2" (click)="quizResultDisplay()">View Questions </button>

              </div>
            </div>

          </div>

          <div class="row text-center" *ngIf="assesmentResult['is_pass'] == true">
            <div class="col-md-12 assesment-result font-subheading">
              Congratulations!
            </div>
            <div *ngIf="(assesmentResult['final_quiz'] != undefined && assesmentResult['final_quiz'] == true); else assesmentCompleted">
              
              <div class="col-md-12 assesment-result body-font text-font-color">
                <p>
                  You have successfully completed the course.
                </p>
              </div>
              <div class="col-md-12 body-font text-font-color">
                <p>Click on "Download Certificate" button below to get your course completion certificate.
                </p>
              </div>
              <div class="col-md-12 mobile-allignment">
                <div class="text-center justify-content-center py-4">
                  <button type="button" class="btn btn-danger bt-md" (click)="downloadCertificate()">Download
                    Certificate</button>
                    <button type="button" class="btn btn-danger bt-md questions mx-2" (click)="quizResultDisplay()">View Questions </button>
                </div>
              </div>
            </div>
            <ng-template #assesmentCompleted>
            <div class="">
              <div class="col-md-12 assesment-result body-font text-font-color">
                <p >You have successfully cleared the assessment.
                </p>
              </div>
              <div class="col-md-12 body-font text-font-color">
                <p>Click on the "Continue Course" button below to proceed.
                </p>
              </div>
              <div class="col-md-12 mobile-allignment">
                <div class="text-center justify-content-center py-4">
                  <button type="button" class="btn btn-danger bt-md" (click)="continueCourse()">Continue Course</button>

                  <button type="button" class="btn btn-danger bt-md questions mx-2" (click)="quizResultDisplay()">View Questions </button>

                </div>
              </div>
            </div>
          </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="space-bottun" *ngIf="quizResult && !isLoading">
  <app-quiz-result [quizList]="resultDetailsPreview"></app-quiz-result>
</div>

<div class="container" *ngIf="lesson_data?.lesson_details?.is_already_completed==true && !isLoading">
  <div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <h3>Warning</h3>
                    <p>You have already completed the assessment to view the assessment result click the below button "View result"</p>
                    <div class="btn-box">
                      <button class="btn danger-btn home-btn"  (click)="ViewResult()"><p class=" home-btn1">View Assessment Result</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="isCourseDone && !isLoading">
<div class="error-area ptb-100">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="error-content">
                  <h3>Warning</h3>
                  <p>{{iserrorStatus}}</p>
                  <div class="btn-box">
                    <button class="btn danger-btn home-btn"  (click)="goBack()"><p class=" home-btn1">Go Back</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>