import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { AuthService } from "app/services/auth.service";
import { SpinnerService } from "app/services/spinner.service";
import { tap } from "rxjs/internal/operators/tap";
import { finalize } from "rxjs/operators";
import { EncryptionService } from "app/services/encryption.service";

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

    auth: any;

    constructor(
        private inj: Injector,
        private spinnerService: SpinnerService,
        private encryptionService:EncryptionService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available

        if (request.url.toLocaleLowerCase().startsWith(environment.apiURL.toLocaleLowerCase()) || request.url.toLocaleLowerCase().startsWith(environment.adminApiURL.toLocaleLowerCase())) {
            let token = localStorage.getItem('qa-academy-auth-storage') ? JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem('qa-academy-auth-storage'))).access_token:null;
            if (token) {
                // setTimeout(() =>  this.auth = this.inj.get(AuthService));
                // if (this.auth && this.auth.currentUserSubject) {
                //     let currentUser = this.auth.currentUserSubject.getValue();
                //     if (currentUser && currentUser.GenerateOn) {
                //         if (((new Date().getTime() - currentUser.GenerateOn.getTime()) / (1000 * 60)) > 150) {
                //             currentUser.GenerateOn = new Date();
                //             this.auth.refreshToken();
                //         }
                //     }
                // }
                request = this.updateRequest(request, token);
            } else {
                request = this.updateRequest(request);
            }
        }
        let value = this.spinnerService.isLoading.getValue();
        this.spinnerService.isLoading.next(++value);
        return this.handler(next, request);
    }

    handler(next, request) {
        return next.handle(request).
            pipe(
                tap((event) => {  },
                    (error: HttpErrorResponse) => {
                        throw error;
                    }),finalize(()=> {
                        let value = this.spinnerService.isLoading.getValue();
                         if(value>0)
                        {
                            this.spinnerService.isLoading.next(--value);
                        }
                    }),
            )
    }

    updateRequest(request: HttpRequest<any>, token?) {

        request = request.clone({
            headers: this.addExtraHeaders(request.headers, token)
        });

        return request;
    }
    addExtraHeaders(headers: HttpHeaders, token): HttpHeaders {
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
}