<div class=" ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <h3>Error 404 : Page Not Found</h3>
                    <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
                    <div class="btn-box">
                      <button class="btn danger-btn home-btn" ><a routerLink="/dashboard" ><p class=" home-btn1">Go To Home Page</p></a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>