
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { EncryptionService } from 'app/services/encryption.service';

@Pipe({
  name: 'videoEncrypt'
})
export class VideoEncryptPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer,
	private encryptionService : EncryptionService) {}
 

public transform(value: any, args?: any): any {
	value = this.encryptionService.get(value);
    let sanitizedValue = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    return sanitizedValue;
  }
}

