import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CourseService } from 'app/services/course.services';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { EncryptionService } from 'app/services/encryption.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-assesment',
  templateUrl: './assesment.component.html',
  styleUrls: ['./assesment.component.scss']
})

export class AssesmentComponent implements OnInit  {
  private onDestroy$: Subject<void> = new Subject<void>();
  public courseDetails: any = {course_title: ""};
  public assetBaseUrl: string;
  public isLoading: boolean = false;
  public courseEnabled: boolean = true;
  public parent_hide:boolean = false;
  public courseErrMsg: string;

  flag=false;
  closeModal: string;
  lessonDetails : any;
  assesmentDetails : any;
  assesmentQuestionIndex = 0;
  selectedChoiceId = -1;
  assesmentTestId = null;
  assesmentResult = null;
  slug : any;
  assessmentName : any;
  notify = '';
  lesson_data:any;
  quizAnswerMap = new Map<string, number>();
  config: CountdownConfig = { leftTime: 0};
  assesmentDeclarationFlag = false;
  assesmentProgressFlag = false;
  assesmentResultFlag = false;
  public isCourseDone:boolean = false;
  iserrorStatus:any;
  choiceSubmitted:boolean = false;
  quizResult:boolean = false;
  resultDetailsPreview:any = [];
  alreadyFailed:boolean = false;

  constructor(private courseService: CourseService, private route: ActivatedRoute,private modalService: NgbModal,
    private fileSaverService: FileSaverService,private httpClient: HttpClient,private toasService : ToastrService,
     private encryptionservice:EncryptionService,private router:Router, private location: Location) { 
     }

  ngOnInit(): void {
    this.route.params.subscribe((param : Params) => {
      this.slug = param['slug'];
      this.assessmentName = param['assesment_slug']; 
      this.isLoading = true;
      this.getCourseDetails(this.slug);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getCourseDetails(slug) {
    this.isLoading = true;
    this.courseService.getCourseDetails(slug).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.assetBaseUrl = this.encryptionservice.get(res.course_assets_base_url);
      this.courseDetails = res.data;
      this.lessonDetails = res.data.chapters;
      this.lessionDetails();
      this.courseEnabled = true;
      
    }, error => {
      this.isLoading = false;
      this.courseErrMsg = error.error.message ? error.error.message:'Contact Support Team';
      this.courseEnabled = false;
    });
  }

  lessionDetails(){
    let apibody = {
      course_slug:this.slug,
      is_assessment:true,
      lesson_or_assessment_slug:this.assessmentName
    }
      this.courseService.getLessionDetails(apibody).subscribe((res)=>{
      this.lesson_data = res['data'];
      this.checkForFailedAssessment();
      this.startAssesment(this.lesson_data['chapter_details'],this.lesson_data['lesson_details'])
      window.scroll(0,0);
    },error =>{
      if(error['status'] == 400){
        this.isLoading = false;
        this.isCourseDone = true;
        // console.log(error);
        this.iserrorStatus = error.error['message'];
      }

    })
  }

  checkForFailedAssessment(){
    let assesmentDetails = this.lesson_data['lesson_details']['assesment_details'];
    if(assesmentDetails['is_failed'] != undefined && assesmentDetails['is_failed'] == true){
        this.alreadyFailed = true;
        this.assesmentTestId = assesmentDetails['assessment_test_id'];
        this.quizResultPreview();
    }else{
      this.alreadyFailed = false;
    }

  }

  
  takeAssesment(assesmentDetails){
    this.isLoading = true;
    this.choiceSubmitted = false;
    this.quizResult = false;
    this.courseService.startAssessment(this.lesson_data['course_details']['course_id'],this.lesson_data['lesson_details']['lesson_id'],assesmentDetails['assesment_id']).subscribe(response =>{
      this.isLoading = false;
      if(response){
        if(response['assesment_test_id'] != undefined){
          this.assesmentTestId = response['assesment_test_id'];
          // console.log('Assesment Id.....' ,this.assesmentTestId )
          this.assesmentQuestionIndex = 0;
          this.calculateStopTimeInSeconds(this.assesmentDetails);
          this.assesmentDeclarationFlag = false;
          this.assesmentProgressFlag = true;
          this.assesmentResultFlag = false;
        }else
           this.toasService.error("Some Error Occured");
      }
    },
    error => {
      this.isLoading = false;
      this.handleError(error);
    }
    );
  }

  checkQuizAnswerMap(questionId,choiceId){
    if(this.quizAnswerMap.get(questionId) != null && this.quizAnswerMap.get(questionId) == choiceId){
      this.selectedChoiceId = this.quizAnswerMap.get(questionId);
      return true;
    }
    return false;
  }

  handleError(error){
    this.isLoading = false;
    if(error['error'] != undefined && error['error']['message'] != undefined){
       this.toasService.error("Error Occured - " + error['error']['message']);
    }
  }

  submitSingleAssesment(questionDetail,questionId){
    if(this.selectedChoiceId == -1){
      this.toasService.error("Please choose your answer.");
      return;
    }else{
       let requestBody = {
          exam_assign_id:questionDetail['exam_assign_id'],
          assesment_test_id:this.assesmentTestId,
          ans:this.selectedChoiceId
      };
      this.choiceSubmitted = true;
      this.courseService.submitSingleAssesment(requestBody).subscribe(response =>{
        this.choiceSubmitted = false;
        if(this.assesmentQuestionIndex < this.assesmentDetails['exam_assigned_list'].length){
          this.assesmentQuestionIndex = this.assesmentQuestionIndex + 1;
          this.selectedChoiceId = -1;
          if(this.assesmentQuestionIndex == this.assesmentDetails['exam_assigned_list'].length){
              this.finalAssesmentSubmit();
          }
        }
      },
      error => {
        this.choiceSubmitted = false;
        this.handleError(error);
      })
      }
  }

  finalAssesmentSubmit(){
    this.isLoading = true;
    this.courseService.submitFinalAssesment(this.assesmentTestId).subscribe(response =>{
      // this.closeAssesmentModal("");
      this.isLoading = false;
      this.assesmentResult = response['result'];
      this.assesmentProgressFlag=false;
      this.assesmentDeclarationFlag = false;
      this.assesmentResultFlag = true;
      // this.triggerModal(this.assesmentResultModal);
      // this.refreshPageDetails();
      this.quizResultPreview();
  },
  error => {
    // this.refreshPageDetails();
    this.handleError(error);
    this.isLoading = false;
  })
  }

  continueCourse(){
    this.quizResult=false;
    this.goToNextCourse();
    // this.router.navigate(['/course/'+this.slug]);
    // this.selectedChoiceId = -1;
    // this.assesmentQuestionIndex = -1;
    // this.assesmentTestId = null;
    // this.modalService.dismissAll();
    // this.quizAnswerMap.clear();
    // this.refreshPageDetails();
  }

  goToNextCourse(){
    try{
      if(this.lesson_data['next_lesson'] && this.lesson_data['next_lesson']['lesson_slug'] != undefined){
        this.router.navigate(['course/'+this.slug + '/lesson/' +this.lesson_data['next_lesson']['lesson_slug']]);
      }
    }catch(err){
      this.router.navigate(['/course/'+this.slug]);
    } 
  }

  downloadCertificate(){
    let item = this.assesmentResult;
    if(item['certificate_url'] != undefined && item['certificate_url'] != null && item['certificate_url'] != ''){
      this.httpClient.get(this.encryptionservice.get(item.certificate_url), {
        observe: 'response',
        responseType: 'blob'
      }).subscribe(res => {
        this.fileSaverService.save(res.body, "Certificate_" + item.certificate_id + ".pdf");
      }, error => {
      });
      return;
    }
  }

  refreshPageDetails() {
    this.courseService.getCourseDetails(this.slug).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.assetBaseUrl = this.encryptionservice.get(res.course_assets_base_url);
      this.courseDetails = res.data;
    });
  }

  retakeAssesment(){
    this.assesmentResultFlag = false;
    this.modalService.dismissAll();
    this.selectedChoiceId = -1;
    this.assesmentQuestionIndex = 0;
    this.assesmentTestId = null;
    this.quizAnswerMap.clear();
    this.quizResult = false;
    //this.alreadyFailed = false;
    //this.startAssesment(this.lessonDetails['chapterDetails'],this.lessonDetails);
    this.takeAssesment(this.assesmentDetails)
  }

  startAssesment(chapterDetails,lessonDetails){
    // this.flag=true;
    this.lessonDetails = lessonDetails;
    this.lessonDetails['chapter_name'] = chapterDetails['chapter_name']
    this.lessonDetails['chapterDetails'] = chapterDetails;
    if(lessonDetails['assesment_details']['assesment_id'] != undefined && lessonDetails['assesment_details']['assesment_id'] != null){
      this.isLoading = false;
      this.courseService.getAssesmentDetails(lessonDetails['assesment_details']['assesment_id']).subscribe(response =>{
        //this.isLoading = false;
        if(response){
          this.isLoading = false;
          this.assesmentDeclarationFlag = true;
          this.assesmentDetails = response['data'];
          if(this.assesmentDetails['exam_assigned_list'].length == 0){
            this.toasService.error("No assesment questions found for this assesment.");
            return;
          }
        }
      },
      error => {
        this.isLoading = false;
        this.handleError(error);
      })
    }
  }

  calculateStopTimeInSeconds(assesmentDetails){
    if(assesmentDetails['assesment_time_in_min'] != undefined){
      let elapsedSeconds = Number(assesmentDetails['assesment_time_in_min']) * 60;
      this.config['leftTime'] = elapsedSeconds;
    }
  }

  goToPrevQuestion(){
    if(this.assesmentQuestionIndex > 0){
      this.assesmentQuestionIndex = this.assesmentQuestionIndex - 1;
      let questionBankId = this.assesmentDetails['exam_assigned_list'][this.assesmentQuestionIndex]['question_bank_id'];
      this.selectedChoiceId = this.quizAnswerMap.get(questionBankId);
    }
  }

  handleEvent(e: CountdownEvent) {
    this.notify = e.action.toUpperCase();
    if (e.action === 'done') {
     if(this.assesmentTestId != null)
      this.finalAssesmentSubmit();
    }
  }

  selectChoice(choiceId,choiceIndex,questionId){
    this.selectedChoiceId = choiceId;
    this.assesmentDetails['exam_assigned_list'][this.assesmentQuestionIndex]['choice_list'].forEach((choice)=>{
          choice['selected'] = false;
    })
    this.assesmentDetails['exam_assigned_list'][this.assesmentQuestionIndex]['choice_list'][choiceIndex]['selected'] = true;
    //maintain a local map for answer keys start
    this.quizAnswerMap.set(questionId,this.selectedChoiceId);
}
goBack()
   {
    // this.router.navigate(['/course/'+this.slug])
    this.location.back()
   }

  addHiglightClass(id){
  document.getElementById(id).classList.add("lms-highlight");
}

removeHiglightClass(id){
document.getElementById(id).classList.remove("lms-highlight");
}
quizResultPreview(){
  this.isLoading = true;
  this.courseService.getResultPreview(this.assesmentTestId).pipe(takeUntil(this.onDestroy$)).subscribe(res =>{
    if(res.success == true){
      this.isLoading = false;
      this.resultDetailsPreview = res.result;
      //  console.log('QuizPreview for assesments.......',this.resultDetailsPreview);
    }
  
  },error=>{
    this.handleError(error);
  });
}

quizResultDisplay(){
  this.quizResult = !this.quizResult;
}
ViewResult(){
  this.router.navigate(['course/'+this.slug + '/assesment/' +this.lesson_data['lesson_details']['assesment_details']['assesment_slug']+'/result/'+this.lesson_data['lesson_details']['assesment_details']['assessment_test_id']])
}
scrollTop()
{
  window.scroll(0,0);
}
}
